<div
  class="col-md-12 mt-2 p-r"
  id="features"
  [ngStyle]="{
    display: listFeatures.length > 3 ? 'block' : 'none'
  }"
>
  <div *ngIf="property?.buildingType.id != 4001" class="col-md-12 key-facts ">
    <span>{{ "detail.propertyFeatures" | translate }}</span>
  </div>
  <div
    *ngIf="
      isMultifamily &&
      customPropertyFeatures.length > 0 &&
      property?.buildingType.id != 4001
    "
    style="font-size: 14px;"
    class="d-flex column-features border-table"
  >
    <div
      class="column-property"
      [ngClass]="{ 'display-none': !feature?.value }"
      *ngFor="let feature of customPropertyFeatures"
    >
      <div *ngIf="feature?.enabled && feature?.value">
        <img [src]="feature.iconSrc" [class]="feature.customClass" alt="" />{{
          feature.displayName
        }}
      </div>
    </div>
  </div>
  <div
    *ngIf="!isMultifamily && property?.buildingType.id != 4001"
    class="d-flex column-features"
  >
    <div style="display: contents;" *ngIf="property?.buildingType.id == 1001">
      <div class="column-property" *ngIf="property?.leedStatus">
        <img src="assets/featuresIcons/eco.svg" alt="" />{{
          "detail.leedStatus" | translate
        }}
      </div>
      <div
        class="column-property"
        *ngIf="property?.fireProtection || property?.fireProtectionType?.name"
      >
        <img src="assets/featuresIcons/highlight.svg" alt="" />
        {{
          property?.fireProtectionType?.name
            ? (property?.fireProtectionType?.name | langSelector)
            : ("detail.fireProtection" | translate)
        }}
      </div>
      <div
        class="column-property"
        *ngIf="property?.electricGenerator || property?.powerGenPrivateArea"
      >
        <img src="assets/featuresIcons/electrical_services.svg" alt="" />{{
          "detail.electricGenerator" | translate
        }}
      </div>
      <div
        class="column-property"
        *ngIf="property?.parkingCovered || property?.hasParkingCovered"
      >
        <img src="assets/featuresIcons/garage.png" alt="" />{{
          "detail.parkingGarage" | translate
        }}
      </div>
      <div
        class="column-property"
        *ngIf="property?.manufacturing || property?.logistics"
      >
        <img src="assets/featuresIcons/Manufacturing_logistics.png" alt="" />{{
          property?.manufacturing
            ? ("detail.manufacturing" | translate)
            : ("detail.logistics" | translate)
        }}
      </div>
      <div class="column-property" *ngIf="property?.skyLights">
        <img src="assets/featuresIcons/light_mode.png" alt="" />{{
          "detail.skyLights" | translate
        }}
      </div>
      <div class="column-property" *ngIf="property?.refrigerated">
        <img src="assets/featuresIcons/thermometer_loss_.png" alt="" />{{
          "detail.refrigerated" | translate
        }}
      </div>
      <div class="column-property" *ngIf="property?.tenancy">
        <img src="assets/featuresIcons/person.svg" alt="" />{{
          property?.tenancy.name | langSelector
        }}
      </div>
      <div class="column-property" *ngIf="property?.includesRetail">
        <img src="assets/featuresIcons/home.png" alt="" />{{
          "detail.mixedUse" | translate
        }}
      </div>
      <div class="column-property" *ngIf="property?.includesOffice">
        <img src="assets/featuresIcons/home.png" alt="" />{{
          "detail.includesOffice" | translate
        }}
      </div>
      <div class="column-property" *ngIf="property?.truckYard">
        <img src="assets/featuresIcons/local_shipping.png" alt="" />{{
          "detail.truckYard" | translate
        }}
      </div>
      <div class="column-property" *ngIf="property?.crossDocking">
        <img src="assets/featuresIcons/local_shipping.png" alt="" />{{
          "detail.crossDocking" | translate
        }}
      </div>
      <div
        class="column-property"
        *ngIf="property?.docks || property?.hasTruckDocking"
      >
        <img src="assets/featuresIcons/local_shipping.png" alt="" />{{
          "detail.truckDocking" | translate
        }}
      </div>
    </div>
    <div style="display: contents;" *ngIf="property?.buildingType.id == 2001">
      <div class="column-property" *ngIf="property?.leedStatus">
        <img src="assets/featuresIcons/eco.svg" alt="" />{{
          property?.leedType.name
        }}
      </div>
      <div class="column-property" *ngIf="property?.securityAccess">
        <img src="assets/featuresIcons/security.svg" alt="" />{{
          "detail.securityAccess" | translate
        }}
      </div>
      <div class="column-property" *ngIf="property?.cctv">
        <img src="assets/featuresIcons/desktop-windows.png" alt="" />{{
          "detail.cctv" | translate
        }}
      </div>
      <div
        class="column-property"
        *ngIf="property?.tenancy || property?.singleTenant"
      >
        <img src="assets/featuresIcons/person.svg" alt="" />{{
          property?.tenancy
            ? (property?.tenancy.name | langSelector)
            : ("detail.singleTenant" | translate)
        }}
      </div>
      <div
        class="column-property"
        *ngIf="property?.fireProtection || property?.fireProtectionType?.name"
      >
        <img src="assets/featuresIcons/highlight.svg" alt="" />
        {{
          property?.fireProtectionType?.name
            ? (property?.fireProtectionType?.name | langSelector)
            : ("detail.fireProtection" | translate)
        }}
      </div>
      <div class="column-property" *ngIf="property?.centralAirConditioning">
        <img src="assets/featuresIcons/ac_unit.svg" alt="" />{{
          "detail.centralAirConditioning" | translate
        }}
      </div>
      <div
        class="column-property"
        *ngIf="property?.electricGenerator || property?.powerGenPrivateArea"
      >
        <img src="assets/featuresIcons/electrical_services.svg" alt="" />{{
          "detail.electricGenerator" | translate
        }}
      </div>
      <div
        class="column-property"
        *ngIf="property?.mixedUse || property?.includesRetail"
      >
        <img src="assets/featuresIcons/home.png" alt="" />{{
          "detail.mixedUse" | translate
        }}
      </div>
      <div
        class="column-property"
        *ngIf="property?.mixedUse || property?.motorLobby"
      >
        <img src="assets/featuresIcons/home.png" alt="" />{{
          "detail.motorLobby" | translate
        }}
      </div>
      <div
        class="column-property"
        *ngIf="property?.parkingCovered || property?.hasParkingCovered"
      >
        <img src="assets/featuresIcons/garage.png" alt="" />{{
          "detail.parkingGarage" | translate
        }}
      </div>
      <div class="column-property" *ngIf="property?.roofGarden">
        <img src="assets/featuresIcons/nature-people.png" alt="" />{{
          "detail.roofTerrace" | translate
        }}
      </div>
    </div>
    <div style="display: contents;" *ngIf="property?.buildingType.id == 3001">
      <div class="column-property" *ngIf="property?.leedStatus">
        <img src="assets/featuresIcons/eco.svg" alt="" />{{
          property?.leedType.name
        }}
      </div>
      <div
        class="column-property"
        *ngIf="property?.includesResidential || property?.includesHotel"
      >
        <img src="assets/featuresIcons/home.png" alt="" />{{
          "detail.mixedUse" | translate
        }}
      </div>

      <div
        class="column-property"
        *ngIf="property?.parkingCovered || property?.hasParkingCovered"
      >
        <img src="assets/featuresIcons/garage.png" alt="" />{{
          "detail.parkingGarage" | translate
        }}
      </div>
      <div class="column-property" *ngIf="property?.electricityMeter">
        <img src="assets/featuresIcons/Electric-meter.png" alt="" />{{
          "detail.electricityMeter" | translate
        }}
      </div>
      <div class="column-property" *ngIf="property?.gasMeter">
        <img src="assets/featuresIcons/gas-meter.png" alt="" />
        {{ "detail.gasMeter" | translate }}
      </div>
      <div class="column-property" *ngIf="property?.waterMeter">
        <img src="assets/featuresIcons/water-meter.png" alt="" />
        {{ "detail.waterMeter" | translate }}
      </div>
    </div>
  </div>
</div>
