import { areAllEquivalent } from "@angular/compiler/src/output/output_ast";
import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../../environments/environment";
import { Glossary } from "../../../../core/models/glossary.model";
import { I18nService } from "../../../services/i18n.service";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";

@Component({
  selector: "app-glossary",
  templateUrl: "./glossary.component.html",
  styleUrls: [
    "./glossary.component.scss",
    "../market-analytics/market-analytics.component.scss",
    "../fii-data-pro/fii-data-pro.component.scss"
  ]
})
export class GlossaryComponent implements OnInit {
  glossary: Glossary[];
  filteredResult: Glossary[];
  isSticky: boolean;
  currentCode: string;
  currentLang: string;
  constructor(
    private siilaTranslationService: SiilaTranslationService,
    private _translate: TranslateService
  ) {
    this.currentCode = environment.platformId;
  }

  ngOnInit(): void {
    this.currentLang = this.siilaTranslationService.getCurrentLanguage();
    this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang; // pt, en
      this.buildGlossary();
    });

    this.buildGlossary();

    this.filteredResult = [...this.glossary];
  }

  private getEnglishGlossary() {
    return [
      {
        letter: "A",
        content: [
          {
            title: "Area Boma",
            description:
              "Acronym for Building Owners and Managers Association. Today it is the most used in negotiations in the corporate market, mainly by foreign companies that feel more secure in adopting American standards. In short, the “Boma” standard applied in Brazil is the sum of the entire private and common area of the property prorated proportionally to the occupants."
          },
          {
            title: "Asset Class",
            description:
              "Acronym for Building Owners and Managers Association. Today it is the most used in negotiations in the corporate market, mainly by foreign companies that feel more secure in adopting American standards. In short, the “Boma” standard applied in Brazil is the sum of the entire private and common area of the property prorated proportionally to the occupants."
          }
        ]
      },
      {
        letter: "B",
        content: [
          {
            title: "BTS (Built to Suit)",
            description:
              "From the English Built To Suit , the acronym BTS indicates a project built to measure for a specific tenant."
          },
          {
            title: "Built Area",
            description:
              "In general built-up area is everything that has coverage."
          }
        ]
      },
      {
        letter: "C",
        content: [
          {
            title: "Carpet Area",
            description:
              "Based on the useful area of the property, but subtracts the “wet area” (which includes bathrooms, pantry, kitchen) and storage, if any. It is widely used in slab negotiations because it can calculate the efficiency of the office for the accommodation of furniture and employees."
          },
          {
            title: "Cap Rate",
            description:
              "Cap Rate is the rate of return an investment property will generate based on its current market value. In literal translation, the English term “Cap Rate” means “capitalization rate” which, as it is calculated as a percentage, allows you to compare the investment made in real estate with other types of investment, such as savings, direct treasury, shares, real estate funds. and etc. SiiLA presents the analysis of the Cap Rate in its three forms: Cap Rate Stabilized, Cap Rate Year 1 and Cap Rate Brasileiro."
          },
          {
            title: "Common Area",
            description:
              "Spaces that are outside the private area that may, or may not, be of free access to tenants. For example: halls, access areas, support and technical areas, among others."
          },
          {
            title: "Condominium",
            description:
              "Value of condominium expenses, which include expenses with cleaning, security, among others of common use to the enterprise."
          },
          {
            title: "CBD (Centra Business District)",
            description:
              "From the English Central Business District, a CBD region is a primary commercial region, that is, consolidated as a commercial region with offices in a certain city."
          }
        ]
      },
      {
        letter: "D",
        content: [
          {
            title: "Developer",
            description:
              "Company responsible for the work of an enterprise, and may be involved in one or more stages within the development of a property."
          },
          {
            title: "Delivery Date",
            description:
              "Date from which the development is available to be occupied. In general, from the date on which all works are completed and with all government authorizations issued, including Habite-se."
          }
        ]
      },
      {
        letter: "F",
        content: [
          {
            title: "Fundo de Investimento Imobilário (FII)",
            description:
              "Real estate investment fund. The assets of a real estate fund can be composed of commercial, residential, rural or urban properties, built or under construction, for later sale, lease or lease. FIIs are traded through shares that are acquired by investors. The shares represent a fraction of the property and return to the investor the profit related to the rent."
          }
        ]
      },
      {
        letter: "G",
        content: [
          {
            title: "GROCS",
            description:
              "The GROCS rate is calculated as a percentage, and reflects the gross occupancy cost. This cost is calculated from the lease amount paid by the store owner, plus the condominium and the promotion and advertising fund (FPP), divided by sales."
          },
          {
            title: "Gross Absorption",
            description:
              "Metric that indicates the total m² leased within a time interval."
          },
          {
            title: "Gross Leasable Area (GLA)",
            description: "Leaseable area in square meters."
          }
        ]
      },
      {
        letter: "H",
        content: [
          {
            title: "Habite-se",
            description:
              "Document that certifies that the property was built in accordance with the standards established by the local City Hall."
          }
        ]
      },
      {
        letter: "I",
        content: [
          {
            title: "Industrial",
            description:
              "Type of enterprise with industrial purposes, which includes logistics condominiums, distribution centers, industrial warehouses, logistics warehouses and factories."
          },
          {
            title: "Inventory",
            description:
              "Total amount of m² existing in a given market, considering all spaces available for lease and occupied. Allows you to scale the size of a market."
          }
        ]
      },
      {
        letter: "M",
        content: [
          {
            title: "Market",
            description:
              "region with established geographic limits in which commercial enterprises are located. Within each region, SiiLA also determines sub-regions, for the best study and analysis of a given commercial real estate market."
          },
          {
            title: "Mixed Use",
            description:
              "Properties with more than one final purpose of use, which may be residential, commercial, industrial, among others."
          },
          {
            title: "Multi-Owner",
            description: "Property that has more than 1 owner."
          },
          {
            title: "Multi-User",
            description: "Property that is occupied by more than 1 tenant."
          }
        ]
      },
      {
        letter: "N",
        content: [
          {
            title: "Net Absorption",
            description:
              "Metric that indicates the difference between the areas effectively leased and returned during a given period."
          },
          {
            title: "New Stock",
            description:
              "Is the total area of offices, condominiums, malls that will be delivered to the market. It allows to recognize the heating of the offer in the market."
          },
          {
            title: "NOI",
            description: "Net Operating Income (NOI)."
          }
        ]
      },
      {
        letter: "O",
        content: [
          {
            title: "Offices",
            description:
              "Type of enterprise with corporate purposes, which can be single-user or multi-tenant."
          },
          {
            title: "Occupancy Rate",
            description:
              "Represents the areas occupied by tenants, in relation to the total stock."
          }
        ]
      },
      {
        letter: "P",
        content: [
          {
            title: "Private Area",
            description:
              "Area considered for the exclusive use of the owner or occupant, which may include the bathroom and kitchen area, when these are within the occupant's set and are available only to the occupant. In the case of balconies and technical areas, such as the space dedicated to air conditioning, they are included in the private area when they are also available only to the occupant, as well as private storage."
          }
        ]
      },
      {
        letter: "R",
        content: [
          {
            title: "Region",
            description:
              "Set of properties that are located in a certain geographic location."
          },
          {
            title: "Retail",
            description: "Type of property that is used for point of sale."
          },
          {
            title: "Request Price",
            description:
              "Represents the rent amount requested by the owner and not the actually practiced (transacted)."
          }
        ]
      },
      {
        letter: "S",
        content: [
          {
            title: "Single User",
            description: "Property that has only 1 tenant."
          },
          {
            title: "Sustainability Certificate",
            description:
              "There are several sustainability certificates that an enterprise can achieve, such as Leed. All attest that a given project was built with respect for the environment, such as energy efficiency, water reuse, among other sustainable issues."
          }
        ]
      },
      {
        letter: "T",
        content: [
          {
            title: "Transated Price",
            description:
              "Price actually paid for a property, both when buying and leasing."
          },
          {
            title: "Transaction",
            description: "Act of selling or leasing a property."
          }
        ]
      },
      {
        letter: "U",
        content: [
          {
            title: "Useful Area",
            description:
              "It will be equal to or smaller than the private area because it excludes from the calculation some items, such as balconies, projection of the areas of walls and pillars. In this case, it is important to be aware when negotiating a property because the useful area gives a more accurate idea of the internal space than the private area."
          }
        ]
      },
      {
        letter: "V",
        content: [
          {
            title: "Vacancy Rate",
            description:
              "Represents the areas available for lease, in relation to the total stock."
          }
        ]
      }
    ];
  }

  private getSpanishGlossary() {
    return [
      {
        letter: "A",
        content: [
          {
            title: "ABR (Área Bruta Rentable)",
            description: "Superficie total arrendable dentro de una propiedad."
          },

          {
            title: "Absorción Bruta",
            description: "Cantidad de m² ocupados entre dos periodos."
          },

          {
            title: "Absorción Neta",
            description:
              "Diferencia neta entre los m² ocupados y desocupados entre dos periodos. "
          },

          {
            title: "Acceso de seguridad",
            description:
              " Punto que opera como filtro de seguridad dentro de una propiedad o instalación. "
          },

          {
            title: "Administrador",
            description:
              "Figura responsable de controlar y gestionar los aspectos administrativos y de arrendamiento de un inmueble."
          },

          {
            title: "Andén",
            description:
              " Espacio destinado a la descarga de materiales y productos."
          }
        ]
      },
      {
        letter: "B",
        content: [
          {
            title: "BTS (Built to Suit)",
            description:
              "Propiedad industrial construida de acuerdo a las características y requerimientos solicitados con antelación por algún inquilino."
          }
        ]
      },
      {
        letter: "C",
        content: [
          {
            title: "Capital Contable",
            description:
              "Diferencia entre los activos y pasivos de una empresa. Mide la inversión realizada y disponible desde un punto de vista neto. "
          },

          {
            title: "CapEx de Mantenimiento",
            description:
              "Recursos necesarios para mantener el estado actual de los inmuebles."
          },

          {
            title: "Cap Rate",
            description:
              "Tasa de capitalización utilizada para determinar el valor de un inmueble en función de los flujos que genera anualmente."
          },

          {
            title: "CBD (Central Business District)",
            description:
              "Distrito Financiero de una ciudad capital, en donde se concentran sus actividades comerciales y edificios de oficinas. "
          },

          {
            title:
              "Certificación LEED (Leadership in Energy & Environmental Design)",
            description:
              "Sistema de certificación internacional para edificios sustentables. Para alcanzar esta certificación, existen una serie de lineamientos ambientales que deben cumplirse. "
          },

          {
            title: "CETES 28",
            description:
              "Instrumento de deuda bursátil emitido por el Gobierno Federal que se adquieren a descuento por debajo de su valor nominal de 100.00 a un plazo de 28 días."
          },

          {
            title: "CETES 91",
            description:
              "Instrumento de deuda bursátil emitido por el Gobierno Federal que se adquieren a descuento por debajo de su valor nominal de 100.00 a un plazo de 91 días."
          },

          {
            title: "CETES 175",
            description:
              "Instrumento de deuda bursátil emitido por el Gobierno Federal que se adquieren a descuento por debajo de su valor nominal de 100.00 a un plazo de 175 días."
          },

          {
            title: "Clase",
            description:
              "Clasificación de un inmueble según ciertas especificaciones de calidad y características."
          },
          {
            title: "Complejo industrial",
            description:
              "También conocido como Parque Industrial. Lugar donde se agrupan inmuebles industriales que comparten ciertas características, como su desarrollador o servicios comunes."
          },
          {
            title: "Cluster Industrial",
            description:
              "También conocido como Zona Industrial. Espacio destinado al uso industrial donde -a diferencia del Parque Industrial- sus miembros pueden o no tener características en común."
          },
          {
            title: "Costo de ocupación",
            description:
              "Costo asociado a la ocupación de un local, así como las cuotas de mantenimiento de áreas comunes y publicidad."
          },
          {
            title: "CTT",
            description:
              "Sub-mercado conformados por los municipios de Cuautitlán, Tultitlán y Tepotzotlán, ubicado en el Mercado Industrial CDMX."
          },
          {
            title: "Cuota de mantenimiento",
            description:
              "Monto que cubre los costos de mantenimiento del espacio comercial (limpieza, reparaciones y mantenimiento en general). "
          }
        ]
      },
      {
        letter: "D",
        content: [
          {
            title: "Desarrollador",
            description:
              "Figura o agrupación responsable del diseño y construcción de un inmueble."
          },
          {
            title: "Disponibilidad",
            description:
              "Superficie total disponible para su ocupación inmediata o que estará disponible en un plazo no mayor a 3 meses, y por tanto puede ser ofertada a nuevos inquilinos."
          }
        ]
      },
      {
        letter: "E",
        content: [
          {
            title:
              "EBITDA (Earnings Before Interest Taxes Depreciation and Amortization)",
            description:
              " Utilidad antes de intereses, impuestos, depreciación y amortización. Se calcula previo a cualquier ajuste por cambios en la valuación de los activos llevados a resultados."
          },

          {
            title: "Estabilizado",
            description:
              " Se considera que un inmueble está estabilizado cuando tiene al menos 85% de ocupación en Oficinas, 90% en Industrial y 80% en Retail."
          }
        ]
      },
      {
        letter: "F",
        content: [
          {
            title: "Flujo de cajas",
            description:
              "Informe financiero que presenta un detalle de los flujos de ingresos y egresos de dinero que tiene una empresa en un período dado."
          },
          {
            title: "FIBRA (Fideicomiso de Infraestructura y Bienes Raíces)",
            description:
              "Informe financiero que presenta un detalle de los flujos de ingresos y egresos de dinero que tiene una empresa en un período dado."
          }
        ]
      },
      {
        letter: "G",
        content: [
          {
            title: "GDP (Gross Domestic Product)",
            description:
              "Producto Interno Bruto, valor monetario total de los bienes y servicios producidos dentro de un país."
          },

          {
            title: "Guante",
            description:
              "Derecho de arrendamiento que se pide de entrada para rentar un local en un centro comercial. Generalmente es un monto no-reembolsable."
          }
        ]
      },
      {
        letter: "I",
        content: [
          {
            title: "Incremento Promedio en Rentas",
            description: "Alza promedio de los precios de renta del mercado."
          },

          {
            title: "Industrial",
            description:
              "Se refiere a aquellas instalaciones que cumplen con los requisitos de empresas manufactureras y de logística distintas de usuarios finales."
          },

          {
            title: "Inflación",
            description:
              "Aumento generalizado y sostenido del nivel de precios existentes en el mercado durante un período de tiempo."
          },

          {
            title: "Inventario",
            description:
              "Acumulación de bienes inmuebles comerciales construidos. Se expresa en metros cuadrados."
          },

          {
            title: "Investor Survey",
            description:
              "Estudio que realiza SiiLA México a los principales jugadores del mercado inmobiliario comercial, con el fin de capturar la percepción y tendencias percibidas por sus inversionistas sobre el rendimiento de sus inversiones e indicadores financieros."
          },

          {
            title: "IPC",
            description:
              "Índice de precios al consumo. Valor numérico que refleja las variaciones que experimentan un determinado conjunto de bienes y servicios (canasta básica) en un período determinado."
          }
        ]
      },
      {
        letter: "L",
        content: [
          {
            title: "Lease spreads",
            description:
              "Diferencial entre los crecimientos en las rentas por encima de la inflación. Esto sucede generalmente al término del contrato de arrendamiento."
          }
        ]
      },
      {
        letter: "M",
        content: [
          {
            title: "Market Cap",
            description:
              "Valor de mercado total expresado en pesos o dólares de las acciones en circulación de una compañía. Se calcula multiplicando el número total de acciones en circulación de una empresa por el precio de mercado actual de una acción. Se utiliza este parámetro para determinar el tamaño de una empresa, en lugar de usar las ventas o las cifras de activos totales."
          },

          {
            title: "Market Cap por m²",
            description:
              "Market Cap dividido entre el total de metros cuadrados de un inmueble."
          },

          {
            title: "Market Share",
            description:
              "Cuota de mercado o participación de mercado, fracción o porcentaje que se tendrá del total del mercado disponible o del segmento de mercado que está siendo suministrado por la compañía."
          },

          {
            title: "Multi-inquilino",
            description:
              "Bien inmueble comercial ocupado por dos o más inquilinos, se requiere de una figura (el inversionista o el comercializador) para que administre el inmueble, pagos de rentas, etc."
          },

          {
            title: "Multi-propietario",
            description:
              "Bien inmueble comercial con dos o más propietarios. No existe una figura única que administre el inmueble en su totalidad."
          }
        ]
      },
      {
        letter: "N",
        content: [
          {
            title: "Nave Industrial",
            description:
              " Edificio industrial acondicionado para uso manufacturero, de almacenaje   logística."
          },

          {
            title: "Nuevo Inventario",
            description:
              " Acumulación de bienes inmuebles comerciales agregados al inventario total. Apertura de nuevos inmuebles o ampliaciones."
          }
        ]
      },
      {
        letter: "O",
        content: [
          {
            title: "Ocupación",
            description: " Proporción del ABR que se encuentra arrendada."
          },

          {
            title: "Oficina",
            description:
              " Tipo de activo que engloba a todos los inmuebles comerciales cuyo uso está destinado a oficinas tanto del sector privado como el de gobierno."
          }
        ]
      },
      {
        letter: "P",
        content: [
          {
            title: "Parque Industrial",
            description:
              " Lugar donde se agrupan espacios inmuebles industriales que comparten características como pueden ser un mismo desarrollador o servicios comunes."
          },

          {
            title: "Patio de maniobras",
            description:
              " Espacio dedicado para el tránsito de camiones, montacargas o gruas necesarias para el traslado de productos."
          },

          {
            title: "Payout Ratio",
            description:
              " Se refiere a la proporción de las ganancias (beneficios de una empresa) pagados a los accionistas comunes en forma de dividendos en efectivo."
          },

          {
            title: "Período de comercialización",
            description:
              " Período de tiempo en que consuma la venta o el pacto de renta de un bien comercial."
          },

          {
            title: "Período de gracia",
            description:
              " Plazo sin pago de rentas paras las adecuaciones de un espacio comercial, ya sea una oficina, nave industrial o local comercial."
          },

          {
            title: "Planta de luz / Planta de energía",
            description:
              " Sistema de generación de corriente alterna de energía eléctrica, transmisión y distribución."
          },

          {
            title: "Planta tipo",
            description:
              " Superficie promedio, en términos de área rentable, de una planta de un edificio de oficinas. Está expresado en metros cuadrados."
          },

          {
            title: "Precio de mercado",
            description:
              " Precio de lista en rentas a propiedades. Es un monto mensual por metro cuadrado. "
          },

          {
            title: "Portafolio",
            description:
              " Grupo de propiedades que comparten características específicas como FIBRA, fecha de compra o ubicación geográfica."
          },

          {
            title: "Propiedad de inversión",
            description: " Bien inmueble que genera ingresos."
          },

          {
            title: "Propietario",
            description: " Persona que posee el derecho de un bien inmueble."
          }
        ]
      },
      {
        letter: "R",
        content: [
          {
            title: "Ratio de estacionamiento",
            description:
              " Muestra la relación entre metros cuadrados de área rentable y el número de cajones de estacionamiento que corresponden a dicha área."
          },

          {
            title: "Resistencia de piso",
            description:
              " Es un indicador de presión para el piso. La presión indica qué cantidad de masa soporta determinada área. En SiiLA es la combinación de kg/cm²."
          },

          {
            title: "Retail",
            description:
              " Tipo de activo que engloba a los centros comerciales."
          }
        ]
      },
      {
        letter: "S",
        content: [
          {
            title: "SiiLA ID",
            description:
              " Identificador de los bienes inmuebles dentro de la plataforma de SiiLA."
          },

          {
            title: "Sistema anti-incendios",
            description:
              " Conjunto de medidas y equipo que se dispone dentro de un activo industrial para protegerlos contra el fuego. "
          },

          {
            title: "Sub-mercado",
            description:
              " Conjunto de propiedades que se agrupan según una área geográfica delimitada según el mercado que ocupan."
          }
        ]
      },
      {
        letter: "T",
        content: [
          {
            title: "Tasa de Descuento",
            description:
              " Costo de capital que se aplica para determinar el valor presente de un pago futuro."
          },

          {
            title: "Tasa de Disponibilidad",
            description:
              " Superficie total disponible para su ocupación inmediata o que estará disponible en un plazo no mayor a 3 meses, y por tanto puede ser ofertada a nuevos inquilinos."
          },

          {
            title: "Tasa de Ocupación",
            description: " De un inmueble, proporción de espacios rentados."
          },

          {
            title: "Ticker",
            description:
              " Disposición de caracteres, generalmente letras, que representan valores particular que cotizan en una bolsa o que se negocian públicamente."
          },

          {
            title: "Tipo de construcción (industrial)",
            description:
              " Material utilizado para la construcción de un activo industrial. Puede ser de concreto armado, block o block y acero, y/o cualquier otro material."
          },

          {
            title: "Transacción",
            description:
              " Precio final pactado para la renta o venta de un bien inmueble."
          }
        ]
      },
      {
        letter: "U",
        content: [
          {
            title: "UDI'S (Unidades de Inversión)",
            description:
              " Unidades de valor establecidas por el Banco de México para solventar las obligaciones de los créditos hipotecarios o de cualquier acto mercantil o financiero."
          },

          {
            title: "Uso mixto",
            description:
              " Desarrollo que combina múltiples usos tales como residencial, oficinas, hoteles, centros comerciales, entretenimiento y lugares comunitarios."
          }
        ]
      },
      {
        letter: "V",
        content: [
          {
            title: "Valor de capitalización",
            description:
              " Medida económica que indica el valor total de todas las acciones de una empresa que coticen en bolsa."
          }
        ]
      }
    ];
  }

  private getPortugueseGlossary() {
    return [
      {
        letter: "A",
        content: [
          {
            title: "ABSORÇÃO BRUTA",
            description:
              "métrica que indica o total de m² locados dentro de um intervalo de tempo."
          },
          {
            title: "ABSORÇÃO LÍQUIDA",
            description:
              "métrica que indica a diferença entre as áreas efetivamente locadas e devolvidas durante determinado período."
          },
          {
            title: "ÁREA CONSTRUÍDA",
            description:
              "em geral área construída é tudo o que tiver cobertura."
          },

          {
            title: "ÁREA BRUTA LOCÁVEL (ABL)",
            description: "área passível de locação em metros quadrados."
          },

          {
            title: "ÁREA PRIVATIVA",
            description:
              "área considerada de uso exclusivo do proprietário ou do ocupante, que podem incluir a área de banheiro e cozinha, quando estes estão dentro do conjunto do ocupante e estão disponíveis apenas para o ocupante. No caso de sacadas e áreas técnicas, como o espaço dedicado ao ar-condicionado, são incluídos na área privativa quando também estão disponíveis apenas para o ocupante, assim como os depósitos privativos."
          },

          {
            title: "ÁREA COMUM",
            description:
              " espaços que estão fora da área privativa que podem, ou não, ser de livre acesso aos condôminos. Por exemplo: halls, áreas de acesso, de suporte e áreas técnicas, entre outras. "
          },

          {
            title: "ÁREA ÚTIL",
            description:
              "será igual ou menor do que a área privativa porque exclui do cálculo alguns itens, como sacadas, projeção das áreas de paredes e pilares. Neste caso, é importante ficar atento quando for negociar um imóvel porque a área útil dá uma noção mais exata do espaço interno do que a área privativa."
          },

          {
            title: `ÁREA DE "CARPETE"`,
            description:
              "Tem como base a área útil do imóvel, mas subtrai a “área molhada” (que contempla banheiros, copa, cozinha) e depósito, se tiver. É muito utilizada em negociações de lajes pois consegue calcular a eficiência do escritório para a acomodação dos mobiliários e dos funcionários."
          },

          {
            title: "ÁREA BOMA",
            description:
              "sigla para Building Owners and Managers Association. Hoje é a mais utilizada nas negociações no mercado corporativo, principalmente por empresas estrangeiras que se sentem mais seguras em adotar os padrões americanos. Resumidamente, o padrão “Boma aplicado no Brasil, é a soma de toda a área privativa e comum do imóvel rateado proporcionalmente aos ocupantes. "
          }
        ]
      },
      {
        letter: "B",
        content: [
          {
            title: "BTS (Built to Suit)",
            description:
              "do inglês Built To Suit, a sigla BTS indica um empreendimento construído sob medida para determinado inquilino."
          }
        ]
      },
      {
        letter: "C",
        content: [
          {
            title: "CAP RATE",
            description: `o Cap Rate é a taxa de retorno que uma propriedade de investimento vai gerar com base no seu valor de mercado atual. Em tradução literal, o termo em inglês "Cap Rate" significa "taxa de capitalização" que, por ser calculada em porcentagem, permite comparar o investimento feito em imóveis com outros tipos de investimento, como a poupança, tesouro direto, ações, fundos imobiliários e etc. A SiiLA traz a análise do Cap Rate em suas três formas Cap Rate Estabilizado, Cap Rate Ano 1 e Cap Rate Brasileiro.`
          },
          {
            title: "CBD (CENTRAL BUSINESS DISTRICT)",
            description:
              "do inglês Central Business District, uma região CBD é uma região comercial primária, ou seja, consolidada como região comercial com escritórios de uma determinada cidade."
          },
          {
            title: "CERTIFICADO DE SUSTENTABILIDADE",
            description:
              "são diversos os certificados de sustentabilidade que um empreendimento pode conquistar, como por exemplo o Leed. Todos atestam que determinado empreendimento foi construído com respeito ao meio ambiente, como por exemplo eficiência energética, reuso de água entre outros quesitos sustentáveis."
          },
          {
            title: "CLASSE DO ATIVO",
            description:
              "classificação do empreendimento de acordo com suas estruturas e atributos técnicos, usada para diferenciar diferentes qualidades de ativos para um mesmo setor. No caso dos shopping centers a classificação é referente ao público consumidor de cada empreendimento."
          },

          {
            title: "CONDOMÍNIO",
            description:
              "valor das despesas condominiais, que incluem despesas com limpeza, segurança, entre outros de uso comum ao empreendimento."
          }
        ]
      },
      {
        letter: "D",
        content: [
          {
            title: "DATA DE ENTREGA",
            description:
              "data a partir da qual o empreendimento está disponível para ser ocupado. Em geral, a partir da data em que todas as obras estão concluídas e com todas as autorizações governamentais emitidas, incluindo o Habite-se."
          }
        ]
      },
      {
        letter: "E",
        content: [
          {
            title: "ESCRITÓRIOS",
            description:
              "tipo de empreendimento com fins corporativos, que pode ser monousuário ou multi-inquilino."
          },
          {
            title: "ESTOQUE",
            description:
              "quantidade total de m² existente em determinado mercado, considerando todos os espaços disponíveis para locação e ocupados. Permite dimensionar o tamanho de um mercado."
          }
        ]
      },
      {
        letter: "F",
        content: [
          {
            title: "FUNDO DE INVESTIMENTO IMOBILIÁRIO (FII)",
            description:
              "Fundo de investimento de base imobiliária. O patrimônio de um fundo imobiliário pode ser composto de imóveis comerciais, residenciais, rurais ou urbanos, construídos ou em construção, para posterior alienação, locação ou arrendamento. Os FIIs são negociados através de cotas que são adquiridas pelos investidores. As cotas representem uma fração do imóvel e retornam ao investidor o lucro referente ao aluguel."
          }
        ]
      },
      {
        letter: "G",
        content: [
          {
            title: "GROCS",
            description:
              "a taxa do GROCS é calculada em porcentagem, e reflete o custo de ocupação bruto. Esse custo é calculado a partir do valor de locação desembolsado pelo lojista, mais o condomínio e o fundo de promoção e propaganda (FPP), dividido pelas vendas."
          }
        ]
      },
      {
        letter: "H",
        content: [
          {
            title: "HABITE-SE",
            description:
              "documento que atesta que o imóvel foi construído de acordo com as normas estabelecidas pela Prefeitura local."
          }
        ]
      },
      {
        letter: "I",
        content: [
          {
            title: "INCORPORADOR",
            description:
              "empresa responsável pela obra de um empreendimento, podendo estar envolvida em uma ou mais etapas dentro do desenvolvimento de um imóvel."
          },
          {
            title: "INDUSTRIAL",
            description:
              "tipo de empreendimento com fins industriais, que engloba condomínios logísticos, centros de distribuição, galpões industriais, galpões logísticos e fábricas."
          }
        ]
      },
      {
        letter: "M",
        content: [
          {
            title: "MERCADO",
            description:
              "região com limites geográficos estabelecidos no qual estão localizados empreendimentos comerciais. Dentro de cada região, a SiiLA determina ainda subregiões, para o melhor estudo e análise de determinado mercado de imóveis comerciais."
          },
          {
            title: "MONOUSUÁRIO",
            description: "propriedade que possui apenas 1 inquilino."
          },
          {
            title: "MULTI-PROPRIETÁRIO",
            description: "propriedade que possui mais de 1 proprietário."
          },
          {
            title: "MULTIUSUÁRIO",
            description: "propriedade que é ocupada por mais de 1 inquilino."
          }
        ]
      },
      {
        letter: "N",
        content: [
          {
            title: "NOI",
            description:
              "Receita Operacional Líquida (NOI, na sigla em inglês)."
          },
          {
            title: "NOVO ESTOQUE",
            description:
              "é a área total de escritórios, condomínios, shoppings que serão entregues ao mercado. Permite reconhecer o aquecimento da oferta no mercado."
          }
        ]
      },
      {
        letter: "P",
        content: [
          {
            title: "PREÇO PEDIDO",
            description:
              "representa o valor de aluguel pedido pelo proprietário e não o de fato praticado (transacionado)."
          },
          {
            title: "PREÇO TRANSACIONADO",
            description:
              "preço efetivamente pago por um imóvel, tanto na compra quanto na locação."
          }
        ]
      },
      {
        letter: "R",
        content: [
          {
            title: "REGIÃO",
            description:
              "conjunto de propriedades que estão localizadas em determinada localização geográfica."
          }
        ]
      },
      {
        letter: "T",
        content: [
          {
            title: "TAXA DE OCUPAÇÃO",
            description:
              "representa as áreas ocupadas por inquilinos, com relação ao estoque total."
          },
          {
            title: "TAXA DE VACÃNCIA",
            description:
              "representa as áreas disponíveis para locação, com relação ao estoque total."
          },
          {
            title: "TRANSAÇÃO",
            description: "ato da venda ou locação de um imóvel."
          }
        ]
      },
      {
        letter: "U",
        content: [
          {
            title: "USO MISTO",
            description:
              "propriedades com mais de uma finalidade final de uso, podendo ser residencial, comercial, industrial, entre outros."
          }
        ]
      },
      {
        letter: "V",
        content: [
          {
            title: "VAREJO",
            description: "tipo de imóvel que é utilizado para ponto de venda."
          }
        ]
      }
    ];
  }

  private buildGlossary() {
    if (!this.currentLang) {
      this.glossary = this.getEnglishGlossary();
      return;
    }
    switch (this.currentLang) {
      case "en":
        this.glossary = this.getEnglishGlossary();
        break;
      case "pt":
        this.glossary = this.getPortugueseGlossary();
        break;
      case "es":
        this.glossary = this.getSpanishGlossary();
        break;
    }
  }

  search(query: string) {
    if (query === "" || !query) {
      this.filteredResult = [...this.glossary];
      return;
    }

    this.filteredResult = this.glossary
      .filter(item =>
        item.content.some(content =>
          content.title.toLowerCase().includes(query.toLowerCase())
        )
      )
      .map(item => {
        return {
          letter: item.letter,
          content: item.content.filter(content =>
            content.title.toLowerCase().includes(query.toLowerCase())
          )
        };
      });
  }

  scrollTo(target: string) {
    const element = document.querySelector("#" + target);
    const headerOffset = 140;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    if (element) {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }

  stickyStatus(isSticky: boolean) {
    this.isSticky = isSticky;
  }
}
