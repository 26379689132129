import { Component, Input, OnInit } from "@angular/core";
import { I18nService } from "../../../services";

@Component({
  selector: "app-areas-measurements-mx",
  templateUrl: "./areas-measurements-mx.component.html",
  styleUrls: ["./areas-measurements-mx.component.scss"]
})
export class AreasMeasurementsMxComponent implements OnInit {
  @Input() property: any;
  @Input() isMultifamily;
  @Input() areasMeasurementsOpts = [];
  @Input() complexInformation;
  typeOpt = 1;
  constructor(private i18n: I18nService) {}

  ngOnInit(): void {}

  getDrivingDistanceLabel() {
    return this.typeOpt === 1
      ? this.i18n.get("detail.drivingDistance")
      : this.i18n.get("detail.drivingDistanceM");
  }
}
