import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class NewFeaturesService {
  lastUpdateKey: string = "lastNewFeaturesUpdate";
  lastNewFaturesUpdate: string = "Thu Jan 26 2023 10:00:00 GMT+0000";

  showNewFeaturesItem$ = new BehaviorSubject<boolean>(
    this.shouldDiaplayNewFeaturesBanner()
  );

  shouldDiaplayNewFeaturesBanner(): boolean {
    let lastStoredUpdate: string;
    try {
      lastStoredUpdate = localStorage.getItem(this.lastUpdateKey);
    } catch (error) {
      lastStoredUpdate = "";
    }

    if (lastStoredUpdate) {
      const lastUpdateTime: number = new Date(
        this.lastNewFaturesUpdate
      ).getTime();
      const lastStoredTime = new Date(lastStoredUpdate).getTime();
      if (lastUpdateTime <= lastStoredTime) {
        return false;
      }
    }
    return true;
  }

  setLastNewFeaturesUpdate() {
    try {
      localStorage.setItem(this.lastUpdateKey, this.lastNewFaturesUpdate);
    } catch (error) {}
    this.showNewFeaturesItem$.next(false);
  }
}
