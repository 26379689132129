import { Component, Inject, Input, OnInit } from "@angular/core";
import { PropertyService } from "../../services/property.services";
import { Location } from "@angular/common";
import { environment } from "../../../../environments/environment";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";

@Component({
  selector: "app-send-notification",
  templateUrl: "./send-notification.component.html",
  styleUrls: ["./send-notification.component.css"]
})
export class SendNotificationComponent implements OnInit {
  subject = "";
  message = "";
  success = false;

  constructor(
    private propertyService: PropertyService,
    private siilaTranslationService: SiilaTranslationService,
    @Inject(MAT_DIALOG_DATA) public id: any | null,
    private _d: MatDialog
  ) {}

  ngOnInit(): void {}

  sendNotification() {
    let user = JSON.parse(localStorage.getItem("user"));
    const fullUrl = `${environment.applicationURL}/property/${
      this.id
    }/lang/${this.siilaTranslationService.getCurrentLanguage()}`;
    let message = {
      toUser: { id: 25943 },
      externalEmail: user.email,
      id: null,
      subject: this.subject,
      buildingId: this.id,
      buildingUrl: fullUrl,
      content: `${this.message}<br>`
    };
    this.propertyService.sendNotification(message).then(resp => {
      this.success = true;
    });
  }

  close() {
    this._d.closeAll();
  }
}
