import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { ImageService } from "../../../core";
import {
  kmlCommercial,
  kmlCommercialMx,
  kmlIndustrial,
  kmlIndustrialMx,
  kmlOffice,
  kmlOfficeMx,
  latLog
} from "../../utils/params";
import { MatDialog } from "@angular/material/dialog";
import { BreakpointObserver } from "@angular/cdk/layout";
import { ImageGalleryComponent } from "../image-gallery/image-gallery.component";
import { AgmMap, KmlMouseEvent } from "@agm/core";
import { environment } from "../../../../environments/environment";
@Component({
  selector: "app-show-images",
  templateUrl: "./show-images.component.html",
  styleUrls: ["./show-images.component.scss"]
})
export class ShowImagesComponent implements OnInit, OnChanges {
  @Input() photos = [];
  @Input() latitude;
  @Input() longitude;
  @Input() buildingTypeId;
  @Input() complexInformation = null;
  zoom: number = 12;
  mapTypeIds = "roadmap";
  showKml = false;
  kmsList = [];
  dialogRef: any;
  isMobile: boolean = false;
  autoCloseTimer: any; // Timer reference}
  locations = [];
  platformId = environment.platformId;

  @ViewChild("duploMap", { static: false }) duploMap: AgmMap;
  map: google.maps.Map;
  ngAfterViewInit(): void {
    // Access the map instance once it's ready
    this.duploMap.mapReady.subscribe((map: google.maps.Map) => {
      this.map = map;
      this.setKmls();

      var mapDiv = document
        .getElementById("map")
        .getElementsByTagName("div")[0];
      var innerDiv = mapDiv.children[0];
      innerDiv.appendChild(document.getElementById("customPanel"));
      this.showCustomPopup = false;
    });
  }

  get photoValidation() {
    return this.photos.length > 1;
  }

  constructor(
    private imageService: ImageService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe(["(max-width: 450px)"])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }
  ngOnChanges() {
    if (this.buildingTypeId) {
      this.mapTypeIds = this.buildingTypeId == 4001 ? "satellite" : "roadmap";
      this.zoom = this.buildingTypeId == 4001 ? 14 : 12;
      this.setKmls();
    }
    this.setMarkers();
  }
  ngOnInit(): void {}

  setKmls() {
    switch (this.buildingTypeId) {
      case 3001:
        this.kmsList =
          this.platformId == "mx" ? kmlCommercialMx : kmlCommercial;
        break;
      case 1001:
        this.kmsList =
          this.platformId == "mx" ? kmlIndustrialMx : kmlIndustrial;
        break;
      case 2001:
        this.kmsList = this.platformId == "mx" ? kmlOfficeMx : kmlOffice;
        break;

      default:
        break;
    }
  }

  getImage(id) {
    return this.imageService.mediumLargeThumbnail(id);
  }

  toggleKml(): void {
    this.showKml = !this.showKml;
    setTimeout(() => {
      this.setLatLon();
    }, 200);
  }

  setLatLon() {
    if (this.showKml) {
      this.latitude += latLog;
      this.longitude += latLog;
      this.zoom = 12;
    } else {
      this.latitude -= latLog;
      this.longitude -= latLog;
      this.zoom = 17;
    }
  }

  showMap() {
    this.mapTypeIds = "roadmap";
  }

  showSatellite() {
    this.mapTypeIds = "satellite";
  }

  openPhotos() {
    this.dialogRef = this.dialog.open(ImageGalleryComponent, {
      height: this.isMobile ? "100%" : "auto",
      width: this.isMobile ? "100%" : "44%",
      maxWidth: "none",
      data: this.photos,
      panelClass: "custom"
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  showCustomPopup = true;
  popupContent = "";
  popupX = 0;
  popupY = 0;

  closePopup(): void {
    this.showCustomPopup = false;
    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer);
      this.autoCloseTimer = null;
    }
  }

  onKmlClick(event: KmlMouseEvent): void {
    // Prevent the default info window from opening
    this.showCustomPopup = true;
    if (!this.map) {
      return;
    }

    const { x, y } = this.latLngToPixel(
      this.map,
      event.latLng as google.maps.LatLng
    );

    this.popupX = x;
    this.popupY = y;

    this.popupContent = event.featureData.name;

    setTimeout(() => {
      var mapDiv = document
        .getElementById("map")
        .getElementsByTagName("div")[1];
      mapDiv.appendChild(document.getElementById("customPanel"));
    }, 200);

    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer);
    }

    // Set a timer to close the popup after 3 seconds
    this.autoCloseTimer = setTimeout(() => {
      this.closePopup();
    }, 3000); // 3000 milliseconds = 3 seconds
  }

  latLngToPixel(
    map: google.maps.Map,
    latLng: google.maps.LatLng
  ): { x: number; y: number } {
    const projection = map.getProjection();
    const scale = Math.pow(2, map.getZoom());

    if (!projection) {
      return { x: 0, y: 0 };
    }

    const bounds = map.getBounds();

    if (!bounds) {
      return { x: 0, y: 0 };
    }

    const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
    const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());

    const worldPoint = projection.fromLatLngToPoint(latLng);

    // Calculate pixel coordinates within the map container
    const x = Math.floor((worldPoint.x - bottomLeft.x) * scale);
    const y = Math.floor((worldPoint.y - topRight.y) * scale) + 20;

    return { x, y };
  }

  setMarkers() {
    if (this.complexInformation) {
      this.complexInformation.warehouses.forEach(pin => {
        this.locations.push({
          latitude: pin.latitude,
          longitude: pin.longitude
        });
      });
    } else {
      this.locations.push({
        latitude: this.latitude,
        longitude: this.longitude
      });
    }
  }

  setNumberPinMarker(index) {
    if (this.buildingTypeId == 4001) {
      return { text: index.toString() };
    }

    return { text: "" };
  }
}
