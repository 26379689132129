<div *ngIf="!success" class="col-md-12" style="padding: 0px;">
  <div class="col-md-12">
    <h4 style="font-size: 16px;" class="label-color" for="">
      {{ "detail.suggestEdit" | translate }}
    </h4>
  </div>
  <div class="col-md-12 mt-2">
    <label for="" class="form-label label-color"
      >{{ "detail.message" | translate }}*</label
    >
    <textarea
      [(ngModel)]="message"
      name=""
      class="form-control mt-2"
      id=""
      cols="30"
      rows="5"
    ></textarea>
  </div>
  <div class="col-md-12 mt-2" style="text-align: end;">
    <button
      (click)="sendNotification()"
      [disabled]="message == ''"
      mat-flat-button
      color="accent"
    >
      {{ "detail.send" | translate }}
    </button>
  </div>
</div>

<div *ngIf="success" style="padding: 0px;">
  <div class="col-md-12">
    <h4 class="label-color">{{ "detail.sendMessage" | translate }}</h4>
    <div class="mt-2">
      <span>{{ "detail.messageSend" | translate }}</span>
    </div>
    <div class="mt-2" style="text-align: center;">
      <button (click)="close()" mat-flat-button color="accent">
        {{ "detail.close" | translate }}
      </button>
    </div>
  </div>
</div>
