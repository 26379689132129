import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class AnalyticsService {
  constructor() {}

  public init() {
    if (
      environment.googleTrackingID &&
      environment.googleTrackingID.length > 0
    ) {
      this.configTrackingID(environment.googleTrackingID);
    }
  }

  private configTrackingID(trackingId: string) {
    try {
      const script1 = document.createElement("script");
      script1.async = true;
      script1.src = "https://www.googletagmanager.com/gtag/js?id=" + trackingId;
      document.head.appendChild(script1);

      const script2 = document.createElement("script");
      var user = JSON.parse(localStorage.getItem("user"));

      script2.innerHTML =
        `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '` +
        trackingId +
        `', {'send_page_view': true});
      `;

      document.head.appendChild(script2);
    } catch (ex) {
      console.error(`Error appending google analytics: ${trackingId}`);
      console.error(ex);
    }
  }
}
