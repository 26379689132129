import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";

import * as $ from "jquery";

@Component({
  selector: "app-methodology",
  templateUrl: "./siila-methodology.component.html",
  styleUrls: [
    "./siila-methodology.component.scss",
    "../static-pages/product-list/product-list.component.scss"
  ]
})
export class SiilaMethodologyComponent implements OnInit {
  translatePathStrategy: string;
  translateSubscription: Subscription;
  currentCode: string;
  playVideo = false;
  browserLang: string = "en";
  constructor(
    private siilaTranslationService: SiilaTranslationService,
    private _translate: TranslateService
  ) {
    this.currentCode = environment.platformId;
    this.translatePathStrategy = `analytics.${this.currentCode}.strategy`;
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.currentCode = environment.platformId;
  }

  ngOnInit(): void {
    this.translateSubscription = this.subscribeToLangChange();
  }

  playPauseVideo() {
    $(".wistia_embed")[0].click();
  }

  subscribeToLangChange() {
    return this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.browserLang != event.lang) {
        this.browserLang = event.lang;
      }
    });
  }
}
