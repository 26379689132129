import { Component, Input, OnInit } from "@angular/core";
import { PropertyOptions } from "../../../services/property-features.service";

@Component({
  selector: "app-property-features-br",
  templateUrl: "./property-features-br.component.html",
  styleUrls: ["./property-features-br.component.scss"]
})
export class PropertyFeaturesBrComponent implements OnInit {
  @Input() property: any;
  @Input() isMultifamily;
  @Input() customPropertyFeatures: PropertyOptions[] = [];
  listFeatures: any = [];

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      let features = document.getElementById("features");
      this.listFeatures = features?.getElementsByTagName("div");
    }, 1000);
  }
}
