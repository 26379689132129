import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { env } from "process";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class PropertyService {
  constructor(private http: HttpClient) {}

  getPropertyId(id) {
    return this.http.get(`${environment.apiURL}/buildings/${id}`).toPromise();
  }

  getUnitMixByPropertyId(id) {
    return this.http
      .get(`${environment.apiURL}/units-mix/building/${id}`)
      .toPromise();
  }

  getFeatureDetailsByPropertyId(id, lang) {
    return this.http
      .get(
        `${environment.apiURL}/building/features/detail/building/${id}/lang/${lang}`
      )
      .toPromise();
  }

  fetchTenantsAutocompleteResults(query: string): Observable<any[]> {
    const apiUrl = `${environment.apiURL}/building-rent-rolls/tenants?query=${query}`;
    return this.http.get<any[]>(apiUrl);
  }

  getFeatureIdsPropertyId(id) {
    return this.http
      .get(`${environment.apiURL}/building/features/building/${id}`)
      .toPromise();
  }

  getPhotosById(id) {
    return this.http
      .get(`${environment.apiURL}/buildingPhotosByBuilding?buildingId=${id}`)
      .toPromise();
  }

  downloadReport(id) {
    return this.http
      .post(`${environment.apiURL}/buildingPrints/word/building`, id)
      .toPromise();
  }

  getProperties(classId, regionId, buildingType, subMarketId = null) {
    let paramsBr = {
      classTypeIds: classId,
      regionId: regionId,
      random: true,
      buildingTypes: [buildingType],
      status: 1,
      propertyStatusIds: [1]
    };

    let paramsMx = {
      subMarketId: subMarketId,
      random: true,
      buildingTypes: [buildingType],
      status: 1,
      propertyStatusIds: [1001]
    };
    return this.http
      .post(
        `${environment.apiURL}/buildings/searchBuildings?page=1&per_page=20`,
        environment.platformId == "mx" ? paramsMx : paramsBr
      )
      .toPromise();
  }

  saveTenantsData(actions) {
    return this.http
      .post(`${environment.apiURL}/building-tenant/tenants`, actions)
      .toPromise();
  }

  sendNotification(mail) {
    return this.http.put(`${environment.apiURL}/inboxs`, mail).toPromise();
  }

  getListingsSpot(id, comparable = false) {
    return this.http
      .get(
        `${environment.apiURL}/buildinglisting/find/spot/building/${
          comparable ? id + "/comparable" : id
        }`
      )
      .toPromise();
  }

  getBuildingRentRollsVacant(id) {
    return this.http
      .get(`${environment.apiURL}/building-rent-rolls/vacant?building=${id}`)
      .toPromise();
  }

  getBuildingRentRolls(id) {
    return this.http
      .get(`${environment.apiURL}/building-rent-rolls?building=${id}`)
      .toPromise();
  }

  getBuildingOccupanciesRegular(id) {
    return this.http
      .get(`${environment.apiURL}/building-occupancies?building=${id}`)
      .toPromise();
  }

  getBuildingOccupancies(id) {
    return this.http
      .get(
        `${environment.apiURL}/building-occupancies/market-rent?building=${id}`
      )
      .toPromise();
  }

  getIndustryPercentages(id) {
    return this.http
      .get(
        `${environment.apiURL}/building-rent-rolls/industry-percentages?building=${id}`
      )
      .toPromise();
  }

  getLastQuarterTenantsInfo(id) {
    return this.http
      .get(`${environment.apiURL}/building-tenant?building=${id}`)
      .toPromise();
  }

  getPointOfInterest(id) {
    return this.http
      .get(`${environment.apiURL}/point-of-interest/building/${id}`)
      .toPromise();
  }

  getListingsSpotVacancy(id) {
    return this.http
      .get(`${environment.apiURL}/buildinglisting/find/all-spot/building/${id}`)
      .toPromise();
  }

  allFundBuildingsByBuilding(id) {
    return this.http
      .get(`${environment.apiURL}/realEstateFundsBuilding/building/${id}`)
      .toPromise();
  }

  getInformationComplex(id) {
    return this.http
      .get(
        `${environment.apiURL}/buildings/industrial-complex/information?complexId=${id}`
      )
      .toPromise();
  }

  getBuildingOccupanciesMx(id) {
    return this.http
      .get(
        `${environment.apiURL}/building-occupancies/latest-quarter?buildingId=${id}`
      )
      .toPromise();
  }
}
