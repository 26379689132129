import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { TransactionsService } from "../../../services/transactions.service";

@Component({
  selector: "app-areas-measurements-trx-mx",
  templateUrl: "./areas-measurements-trx-mx.component.html",
  styleUrls: ["./areas-measurements-trx-mx.component.scss"]
})
export class AreasMeasurementsTrxMxComponent implements OnInit {
  @Input() transaction: any;
  typeOpt = 1;
  typeOptCurrency = 1;
  platformId = environment.platformId;
  portfolioObj = null;
  foundPortfolio;

  constructor(private transactionService: TransactionsService) {}

  ngOnInit() {
    this.validatePortfolio();
    if (this.transaction?.isImperialMeasure == true) {
      this.typeOptCurrency = 2;
    }

    if (this.transaction.originalCurrency == "USD") {
      this.typeOpt = 2;
    }
  }

  async validatePortfolio() {
    if (this.transaction.corporationPortfolio?.id) {
      this.portfolioObj = await this.transactionService.getCorporationPortfolio(
        this.transaction.corporationPortfolio?.id
      );
      this.foundPortfolio = false;
    }

    if (this.transaction.fundPortfolio?.id) {
      this.portfolioObj = await this.transactionService.getFundPortfolio(
        this.transaction.fundPortfolio?.id
      );
      this.foundPortfolio = true;
    }
  }

  redirectPortfolio() {
    window.open(
      `${environment.serverURL}/#!${
        this.foundPortfolio
          ? "/realEstateFund/portfolio/" + this.transaction.fundPortfolio?.id
          : "/corporation/portfolio/" +
            this.transaction.corporationPortfolio?.id
      }`,
      "blank"
    );
  }
}
