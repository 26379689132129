<div
  class="col-md-12 mt-2 p-r"
  id="features"
  [ngStyle]="{
    display: listFeatures.length > 2 ? 'block' : 'none'
  }"
  *ngIf="property?.buildingType.id != 3001"
>
  <div class="col-md-12 key-facts ">
    <span>{{ "detail.propertyFeatures" | translate }}</span>
  </div>
  <div
    *ngIf="isMultifamily && customPropertyFeatures.length > 0"
    style="font-size: 14px;"
    class="d-flex column-features border-table"
  >
    <div
      class="column-property"
      [ngClass]="{ 'display-none': !feature?.value }"
      *ngFor="let feature of customPropertyFeatures"
    >
      <div *ngIf="feature?.enabled && feature?.value">
        <img [src]="feature.iconSrc" [class]="feature.customClass" alt="" />{{
          feature.displayName
        }}
      </div>
    </div>
  </div>
  <div *ngIf="!isMultifamily" class="d-flex column-features">
    <div class="column-property" *ngIf="property?.twentyFourHourSecurity">
      <img src="assets/featuresIcons/security.svg" alt="" />{{
        "detail.twentyFourHourSecurity" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.armoredCabin">
      <img src="assets/featuresIcons/security.svg" alt="" />{{
        "detail.bulletProofCabin" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.mixedUse">
      <img src="assets/featuresIcons/home.png" alt="" />{{
        "detail.mixedUse" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.hoursMaintenance24">
      <img src="assets/featuresIcons/build-circle.png" alt="" />{{
        "detail.24HourMaintenance" | translate
      }}
    </div>

    <div class="column-property" *ngIf="property?.bikeRental">
      <img src="assets/featuresIcons/pedal_bike.svg" alt="" />{{
        "detail.bikeRental" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.businessCenter">
      <img src="assets/featuresIcons/desktop-windows.png" alt="" />{{
        "detail.businessCenter" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.dogPark">
      <img src="assets/featuresIcons/pets.png" alt="" />{{
        "detail.dogPark" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.packageCenter">
      <img src="assets/featuresIcons/deployed-code.png" alt="" />{{
        "detail.packageCenter" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.pool">
      <img src="assets/featuresIcons/pool.png" alt="" />{{
        "detail.pool" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.retailOnSite">
      <img src="assets/featuresIcons/storefront.png" alt="" />{{
        "detail.retailOnSite" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.retailOnSite">
      <img src="assets/featuresIcons/storefront.png" alt="" />{{
        "detail.retailOnSite" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.rooftopLounge">
      <img src="assets/featuresIcons/restaurant.png" alt="" />{{
        "detail.rooftopLounge" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.bicycleRack">
      <img src="assets/featuresIcons/pedal_bike.svg" alt="" />{{
        "detail.bicycleRack" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.securityAccess">
      <img src="assets/featuresIcons/security.svg" alt="" />{{
        "detail.securityAccess" | translate
      }}
    </div>

    <div class="column-property" *ngIf="property?.sprinklers">
      <img src="assets/featuresIcons/highlight.svg" alt="" />{{
        "detail.sprinklers" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.washerDryerIncluded">
      <img src="assets/featuresIcons/laundry.png" alt="" />{{
        "detail.WasherDryerIncluded" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.washerDryerHookup">
      <img src="assets/featuresIcons/laundry.png" alt="" />{{
        "detail.WasherDryerHookup" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.furnished">
      <img src="assets/featuresIcons/home.png" alt="" />{{
        "detail.furnished" | translate
      }}
    </div>

    <div class="column-property" *ngIf="property?.crossDocking">
      <img src="assets/featuresIcons/local_shipping.png" alt="" />{{
        "detail.crossDocking" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.hasLockerRooms">
      <img src="assets/featuresIcons/living.png" alt="" />{{
        "detail.lockerRoom" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.refrigerated">
      <img src="assets/featuresIcons/thermometer_loss_.png" alt="" />{{
        "detail.refrigerated" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.skyLights">
      <img src="assets/featuresIcons/light_mode.png" alt="" />{{
        "detail.skyLights" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.sluiceGate">
      <img src="assets/featuresIcons/water_drop.png" alt="" />{{
        "detail.sluiceGate" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.truckYard">
      <img src="assets/featuresIcons/local_shipping.png" alt="" />{{
        "detail.truckYard" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.plugAndPlay">
      <img src="assets/featuresIcons/plug-icon-color-SiiLA.png" alt="" />
      {{ "detail.plugAndPlay" | translate }}
    </div>
    <div class="column-property" *ngIf="property?.coreAndShel">
      <img src="assets/featuresIcons/warehouse-SiiLA.png" alt="" />
      {{ "detail.coreAndShel" | translate }}
    </div>
    <div class="column-property" *ngIf="property?.heliport">
      <img src="assets/featuresIcons/helicopter.png" alt="" />{{
        "detail.heliport" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.leedStatus">
      <img src="assets/featuresIcons/eco.svg" alt="" />{{
        "detail.leedStatus" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.electricGenerator">
      <img src="assets/featuresIcons/electrical_services.svg" alt="" />{{
        "detail.electricGenerator" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.centralAirConditioning">
      <img src="assets/featuresIcons/ac_unit.svg" alt="" />{{
        "detail.centralAirConditioning" | translate
      }}
    </div>
    <div class="column-property" *ngIf="property?.raisedFloor">
      <img src="assets/featuresIcons/plug-icon-color-SiiLA.png" alt="" />
      {{ "detail.raisedFloor" | translate }}
    </div>
    <div class="column-property" *ngIf="property?.neverUsed">
      <img src="assets/featuresIcons/certified.png" alt="" />
      {{ "detail.neverUsed" | translate }}
    </div>
    <div class="column-property" *ngIf="property?.hasRestrooms">
      <img src="assets/featuresIcons/restroom-icon.png" alt="" />
      {{ "detail.hasRestrooms" | translate }}
    </div>
    <div class="column-property" *ngIf="property?.hasElevators">
      <img src="assets/featuresIcons/elevator.png" alt="" />
      {{ "detail.elevators" | translate }}
    </div>
    <div class="column-property" *ngIf="property?.pool">
      <img src="assets/featuresIcons/pool.png" alt="" />
      {{ "detail.pool" | translate }}
    </div>
    <div class="column-property" *ngIf="property?.fitnessCenter">
      <img src="assets/featuresIcons/fitness_center.png" alt="" />
      {{ "detail.FitnessCenter" | translate }}
    </div>
    <div class="column-property" *ngIf="property?.playground">
      <img src="assets/featuresIcons/restroom-icon.png" alt="" />
      {{ "detail.playGround" | translate }}
    </div>
    <div class="column-property" *ngIf="property?.dogPark">
      <img src="assets/featuresIcons/restroom-icon.png" alt="" />
      {{ "detail.dogPark" | translate }}
    </div>
    <div class="column-property" *ngIf="property?.petFriendly">
      <img src="assets/featuresIcons/pets.png" alt="" />
      {{ "detail.petFriendly" | translate }}
    </div>
    <div class="column-property" *ngIf="property?.ceilingFans">
      <img src="assets/featuresIcons/restroom-icon.png" alt="" />
      {{ "detail.ceilingFans" | translate }}
    </div>
  </div>
</div>
