import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ContactDialogComponent } from "../../../shared/components/dialogs/contact-dialog/contact-dialog.component";
import { NGXLogger } from "ngx-logger";
import { WindowRef } from "../../../window-ref.service";

@Injectable()
export class ContactDialogService {
  dialogRef: any;

  constructor(
    public _d: MatDialog,
    private _logger: NGXLogger,
    private _windowRef: WindowRef
  ) {}

  /**
   * @param {*} [data]
   */
  openDialog(data?): void {
    const dialogRef = this._d.open(ContactDialogComponent, {
      height: "325px",
      width: "550px",
      data: data || {
        title: "SiiLA",
        icon_path: "/assets/img/siilabrandlogo.png"
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  /**
   * @returns
   */
  close() {
    this._d.closeAll();
  }
}
