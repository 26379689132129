import { Injectable, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Injectable({ providedIn: "root" })
export class PWAService {
  public deferredInstallPrompt: any;
  //made change to false

  constructor(private cookies: CookieService) {}

  promptPWAInstall() {
    if (this.deferredInstallPrompt) {
      this.deferredInstallPrompt.prompt().catch((err: any) => {});
    }
  }

  setDeferredPrompt(prompt: any) {
    this.deferredInstallPrompt = prompt;

    this.deferredInstallPrompt.userChoice.then((evt: any) => {
      if (evt) {
      }
    });
  }
}
