import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-detail-carousel",
  templateUrl: "./detail-carousel.component.html",
  styleUrls: ["./detail-carousel.component.scss"]
})
export class DetailCarouselComponent {
  @Input() news: any;
  @Input() image: any;
  constructor(public sanitizer: DomSanitizer) {}

  headerImageNews(item: string): string {
    const url = item.replace("***", "1280w");
    return `${environment.cloudfrontURL}${url}`;
  }
}
