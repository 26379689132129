import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class RegionLoaderService {
  loadedRegion$ = new BehaviorSubject<any>(null);

  constructor() {
    this.loadedRegion$.next(environment.platformId);
  }

  getPlatform(): Observable<any> {
    return this.loadedRegion$.asObservable();
  }
}
