<div class="d-flex key-facts-columns border-table">
  <div class="col-md-12 p-0">
    <mat-button-toggle-group
      class="toggle-group w-100 toggle-options"
      appearance="legacy"
      name="fontStyle"
      aria-label="Font Style"
    >
      <mat-button-toggle
        [ngClass]="{ 'active-toggle': typeOpt === 1 }"
        (click)="typeOpt = 1"
        class="toogle-btn border"
      >
        {{ "transaction.mxn" | translate }}</mat-button-toggle
      >
      <mat-button-toggle
        [ngClass]="{
          'active-toggle': typeOpt === 2
        }"
        (click)="typeOpt = 2"
        class="toogle-btn border"
      >
        {{ "transaction.usd" | translate }}</mat-button-toggle
      >
      <mat-button-toggle
        [ngClass]="{ 'active-toggle': typeOptCurrency === 1 }"
        (click)="typeOptCurrency = 1"
        class="toogle-btn border"
      >
        {{ "transaction.meters" | translate }}</mat-button-toggle
      >
      <mat-button-toggle
        [ngClass]="{
          'active-toggle': typeOptCurrency === 2
        }"
        (click)="typeOptCurrency = 2"
        class="toogle-btn border"
      >
        {{ "transaction.feet" | translate }}</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>

  <div class="d-content" *ngIf="transaction?.type == 'SALE'">
    <div class="d-content" *ngIf="typeOpt === 1">
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.soldArea && typeOptCurrency === 1"
      >
        {{ "transaction.privateSoldArea" | translate }}
      </div>
      <div
        class="column label-color2"
        *ngIf="transaction?.soldArea && typeOptCurrency === 1"
      >
        {{ transaction?.soldArea | localNumber: "1.2-2" }}
      </div>
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.soldArea && typeOptCurrency === 2"
      >
        {{ "transaction.privateSoldAreaFt" | translate }}
      </div>
      <div
        class="column label-color2"
        *ngIf="transaction?.soldArea && typeOptCurrency === 2"
      >
        {{ transaction?.soldArea * 10.764 | localNumber: "1.2-2" }}
      </div>
      <div class="column label-color flexB-60" *ngIf="transaction?.salePrice">
        {{ "transaction.salePriceMxn" | translate }}
      </div>
      <div class="column label-color2" *ngIf="transaction?.salePrice">
        {{ transaction?.salePrice | localNumber: "1.2-2" }}
      </div>
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.sellPricePerArea && typeOptCurrency === 1"
      >
        {{ "transaction.salePriceMxnM2" | translate }}
      </div>
      <div
        class="column label-color2"
        *ngIf="transaction?.sellPricePerArea && typeOptCurrency === 1"
      >
        {{ transaction?.sellPricePerArea | localNumber: "1.2-2" }}
      </div>
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.sellPricePerArea && typeOptCurrency === 2"
      >
        {{ "transaction.salePriceMxnF2" | translate }}
      </div>
      <div
        class="column label-color2"
        *ngIf="transaction?.sellPricePerArea && typeOptCurrency === 2"
      >
        {{ transaction?.sellPricePerArea * 10.764 | localNumber: "1.2-2" }}
      </div>
      <div class="column label-color flexB-60" *ngIf="transaction?.noi">
        {{ "transaction.noiAtStabilization" | translate }}
      </div>
      <div class="column label-color2" *ngIf="transaction?.noi">
        {{ transaction?.noi | localNumber: "1.2-2" }}
      </div>
    </div>
    <div class="d-content" *ngIf="typeOpt === 2">
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.soldArea && typeOptCurrency === 1"
      >
        {{ "transaction.privateSoldArea" | translate }}
      </div>
      <div
        class="column label-color2"
        *ngIf="transaction?.soldArea && typeOptCurrency === 1"
      >
        {{ transaction?.soldArea | localNumber: "1.2-2" }}
      </div>
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.soldArea && typeOptCurrency === 2"
      >
        {{ "transaction.privateSoldAreaFt" | translate }}
      </div>
      <div
        class="column label-color2"
        *ngIf="transaction?.soldArea && typeOptCurrency === 2"
      >
        {{ transaction?.soldArea * 10.764 | localNumber: "1.2-2" }}
      </div>
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.salePriceUSD"
      >
        {{ "transaction.salePriceUsd" | translate }}
      </div>
      <div class="column label-color2" *ngIf="transaction?.salePriceUSD">
        {{ transaction?.salePriceUSD | localNumber: "1.2-2" }}
      </div>
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.sellPricePerAreaUSD && typeOptCurrency === 1"
      >
        {{ "transaction.salePriceUsdM2" | translate }}
      </div>
      <div
        class="column label-color2"
        *ngIf="transaction?.sellPricePerAreaUSD && typeOptCurrency === 1"
      >
        {{ transaction?.sellPricePerAreaUSD | localNumber: "1.2-2" }}
      </div>
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.sellPricePerAreaUSD && typeOptCurrency === 2"
      >
        {{ "transaction.salePriceUsdF2" | translate }}
      </div>
      <div
        class="column label-color2"
        *ngIf="transaction?.sellPricePerAreaUSD && typeOptCurrency === 2"
      >
        {{ transaction?.sellPricePerAreaUSD * 10.764 | localNumber: "1.2-2" }}
      </div>
      <div class="column label-color flexB-60" *ngIf="transaction?.noiUSD">
        {{ "transaction.noiAtStabilizationUsd" | translate }}
      </div>
      <div class="column label-color2" *ngIf="transaction?.noiUSD">
        {{ transaction?.noiUSD | localNumber: "1.2-2" }}
      </div>
    </div>

    <div class="column label-color flexB-60" *ngIf="transaction?.capRate">
      {{ "transaction.capRateStbilized" | translate }}
    </div>
    <div class="column label-color2" *ngIf="transaction?.capRate">
      {{ transaction?.capRate | localNumber: "1.2-2" }}%
    </div>
    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.saleCommissions"
    >
      {{ "transaction.saleCommissions" | translate }}
    </div>
    <div class="column label-color2" *ngIf="transaction?.saleCommissions">
      {{ transaction?.saleCommissions | localNumber: "1.0-0" }}%
    </div>
    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.bts || transaction?.bts == false"
    >
      {{ "transaction.bts" | translate }}
    </div>
    <div
      class="column label-color2"
      *ngIf="transaction?.bts || transaction?.bts == false"
    >
      {{
        transaction?.bts
          ? ("detail.yes" | translate)
          : ("detail.no" | translate)
      }}
    </div>
    <div
      class="column label-color flexB-60"
      *ngIf="
        transaction?.saleAndLeaseback || transaction?.saleAndLeaseback == false
      "
    >
      {{ "transaction.saleAndLeaseback" | translate }}
    </div>
    <div
      class="column label-color2"
      *ngIf="
        transaction?.saleAndLeaseback || transaction?.saleAndLeaseback == false
      "
    >
      {{
        transaction?.saleAndLeaseback
          ? ("detail.yes" | translate)
          : ("detail.no" | translate)
      }}
    </div>
  </div>
  <div class="d-content" *ngIf="transaction?.type == 'LEASE'">
    <div class="d-content" *ngIf="typeOpt === 1">
      <div class="column label-color flexB-60" *ngIf="transaction?.leasedArea">
        {{
          typeOptCurrency === 1
            ? ("transaction.leasedArea" | translate)
            : ("transaction.leasedAreaFt" | translate)
        }}
      </div>
      <div class="column label-color2" *ngIf="transaction?.leasedArea">
        {{
          typeOptCurrency === 1
            ? (transaction?.leasedArea | localNumber: "1.2-2")
            : (transaction?.leasedArea * 10.764 | localNumber: "1.2-2")
        }}
      </div>
      <div class="column label-color flexB-60" *ngIf="transaction?.monthlyRent">
        {{ "transaction.monthlyRentMxn" | translate }}
      </div>
      <div class="column label-color2" *ngIf="transaction?.monthlyRent">
        {{ transaction?.monthlyRent | localNumber: "1.2-2" }}
      </div>
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.monthlyRentPerArea"
      >
        {{
          typeOptCurrency === 1
            ? ("transaction.monthlyRentPerArea" | translate)
            : ("transaction.monthlyRentPerAreaFt" | translate)
        }}
      </div>
      <div class="column label-color2" *ngIf="transaction?.monthlyRentPerArea">
        {{
          typeOptCurrency === 1
            ? (transaction?.monthlyRentPerArea | localNumber: "1.2-2")
            : (transaction?.monthlyRentPerArea / 10.764 | localNumber: "1.2-2")
        }}
      </div>
      <div class="column label-color flexB-60" *ngIf="transaction?.condo">
        {{
          typeOptCurrency === 1
            ? ("transaction.monthlyManagementFees" | translate)
            : ("transaction.monthlyManagementFeesFt" | translate)
        }}
      </div>
      <div class="column label-color2" *ngIf="transaction?.condo">
        {{
          typeOptCurrency === 1
            ? (transaction?.condo | localNumber: "1.2-2")
            : (transaction?.condo / 10.764 | localNumber: "1.2-2")
        }}
      </div>
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.tenantImprovements"
      >
        {{
          typeOptCurrency === 1
            ? ("transaction.tenantImprovements" | translate)
            : ("transaction.tenantImprovementsFt" | translate)
        }}
      </div>
      <div class="column label-color2" *ngIf="transaction?.tenantImprovements">
        {{
          typeOptCurrency === 1
            ? (transaction?.tenantImprovements | localNumber: "1.2-2")
            : (transaction?.tenantImprovements * 10.764 | localNumber: "1.2-2")
        }}
      </div>
    </div>

    <div class="d-content" *ngIf="typeOpt === 2">
      <div class="column label-color flexB-60" *ngIf="transaction?.leasedArea">
        {{
          typeOptCurrency === 1
            ? ("transaction.leasedArea" | translate)
            : ("transaction.leasedAreaFt" | translate)
        }}
      </div>
      <div class="column label-color2" *ngIf="transaction?.leasedArea">
        {{
          typeOptCurrency === 1
            ? (transaction?.leasedArea | localNumber: "1.2-2")
            : (transaction?.leasedArea * 10.764 | localNumber: "1.2-2")
        }}
      </div>
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.monthlyRentUSD"
      >
        {{ "transaction.monthlyRentUsd" | translate }}
      </div>
      <div class="column label-color2" *ngIf="transaction?.monthlyRentUSD">
        {{ transaction?.monthlyRentUSD | localNumber: "1.2-2" }}
      </div>
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.monthlyRentPerAreaUSD"
      >
        {{
          typeOptCurrency === 1
            ? ("transaction.monthlyRentPerAreaUsd" | translate)
            : ("transaction.monthlyRentPerAreaUsdFt" | translate)
        }}
      </div>
      <div
        class="column label-color2"
        *ngIf="transaction?.monthlyRentPerAreaUSD"
      >
        {{
          typeOptCurrency === 1
            ? (transaction?.monthlyRentPerAreaUSD | localNumber: "1.2-2")
            : (transaction?.monthlyRentPerAreaUSD / 10.764
              | localNumber: "1.2-2")
        }}
      </div>
      <div class="column label-color flexB-60" *ngIf="transaction?.condoUSD">
        {{
          typeOptCurrency === 1
            ? ("transaction.monthlyManagementFeesUsd" | translate)
            : ("transaction.monthlyManagementFeesUsdFt" | translate)
        }}
      </div>
      <div class="column label-color2" *ngIf="transaction?.condoUSD">
        {{
          typeOptCurrency === 1
            ? (transaction?.condoUSD | localNumber: "1.2-2")
            : (transaction?.condoUSD / 10.764 | localNumber: "1.2-2")
        }}
      </div>
      <div
        class="column label-color flexB-60"
        *ngIf="transaction?.tenantImprovementsUSD"
      >
        {{
          typeOptCurrency === 1
            ? ("transaction.tenantImprovementsUsd" | translate)
            : ("transaction.tenantImprovementsUsdFt" | translate)
        }}
      </div>
      <div
        class="column label-color2"
        *ngIf="transaction?.tenantImprovementsUSD"
      >
        {{
          typeOptCurrency === 1
            ? (transaction?.tenantImprovementsUSD | localNumber: "1.2-2")
            : (transaction?.tenantImprovementsUSD * 10.764
              | localNumber: "1.2-2")
        }}
      </div>
    </div>

    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.leasingCommission"
    >
      {{ "transaction.leasingCommission" | translate }}
    </div>
    <div class="column label-color2" *ngIf="transaction?.leasingCommission">
      {{ transaction?.leasingCommission }}%
    </div>
    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.freeRentMonths"
    >
      {{ "transaction.freeRentMonths" | translate }}
    </div>
    <div class="column label-color2" *ngIf="transaction?.freeRentMonths">
      {{ transaction?.freeRentMonths }}
    </div>
    <div
      class="column label-color flexB-60"
      *ngIf="
        transaction?.escalations && (transaction?.escalations | langSelector)
      "
    >
      {{ "transaction.formattedEsc" | translate }}
    </div>
    <div
      class="column label-color2"
      *ngIf="
        transaction?.escalations && (transaction?.escalations | langSelector)
      "
    >
      {{ transaction?.escalations | langSelector }}
    </div>
    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.leaseType?.name"
    >
      {{ "transaction.leaseType" | translate }}
    </div>
    <div class="column label-color2" *ngIf="transaction?.leaseType?.name">
      {{ transaction?.leaseType.name }}
    </div>
  </div>
</div>

<div
  *ngIf="transaction?.type == 'SALE' && portfolioObj"
  class="col-md-12 key-facts mt-2"
>
  <span>{{ "transaction.portafolioDetails" | translate }}</span>
</div>
<div
  *ngIf="transaction?.type == 'SALE' && portfolioObj"
  class="d-flex key-facts-columns border-table"
>
  <div
    class="column label-color"
    *ngIf="portfolioObj?.corporationPortfolio || portfolioObj?.portfolio"
  >
    {{ "transaction.portfolio" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="portfolioObj?.corporationPortfolio || portfolioObj?.portfolio"
  >
    <a (click)="redirectPortfolio()" class="link">{{
      portfolioObj?.corporationPortfolio
        ? portfolioObj?.corporationPortfolio?.corporationWithPortfolios
            .corporation.name +
          ": " +
          portfolioObj?.corporationPortfolio?.name
        : (portfolioObj?.portfolio.fund.name | langSelector) +
          ": " +
          portfolioObj?.portfolio?.name
    }}</a>
  </div>
  <div class="column label-color" *ngIf="portfolioObj?.count">
    {{ "transaction.totalPropertiesPortfolio" | translate }}
  </div>
  <div class="column label-color2" *ngIf="portfolioObj?.count">
    {{ portfolioObj?.count }}
  </div>
  <div
    class="column label-color"
    *ngIf="portfolioObj?.salePriceMXNTotal || portfolioObj?.salePriceUSDTotal"
  >
    {{
      typeOpt === 1
        ? ("transaction.salesPricePortfolio" | translate)
        : ("transaction.salesPricePortfolioUsd" | translate)
    }}
  </div>
  <div
    class="column label-color2"
    *ngIf="portfolioObj?.salePriceMXNTotal || portfolioObj?.salePriceUSDTotal"
  >
    {{
      typeOpt === 1
        ? (portfolioObj?.salePriceMXNTotal | localNumber: "1.0-0")
        : (portfolioObj?.salePriceUSDTotal | localNumber: "1.0-0")
    }}
  </div>
  <div class="column label-color" *ngIf="portfolioObj?.soldAreaTotal">
    {{
      typeOptCurrency === 1
        ? ("transaction.totalPortfolio" | translate)
        : ("transaction.totalPortfolioFt" | translate)
    }}
  </div>
  <div class="column label-color2" *ngIf="portfolioObj?.soldAreaTotal">
    {{
      typeOptCurrency === 1
        ? (portfolioObj?.soldAreaTotal | localNumber: "1.0-0")
        : (portfolioObj?.soldAreaTotal * 10.764 | localNumber: "1.0-0")
    }}
  </div>
  <div class="column label-color" *ngIf="portfolioObj?.occupancyTotal">
    {{ "transaction.portafolioOccupancy" | translate }}
  </div>
  <div class="column label-color2" *ngIf="portfolioObj?.occupancyTotal">
    {{ portfolioObj?.occupancyTotal | localNumber: "1.2-2" }}%
  </div>
  <div
    class="column label-color"
    *ngIf="portfolioObj?.caprateNoi || portfolioObj?.caprateNoiUSD"
  >
    {{ "transaction.portafolioCapRate" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="portfolioObj?.caprateNoi || portfolioObj?.caprateNoiUSD"
  >
    {{
      typeOpt === 1
        ? (portfolioObj?.caprateNoi | localNumber: "1.2-2")
        : (portfolioObj?.caprateNoiUSD | localNumber: "1.2-2")
    }}%
  </div>
</div>
