<div class="transactions-container" *ngIf="transactions?.length > 0">
  <span class="title border-reix">{{ getComponentTitle() }}</span>

  <mat-button-toggle-group
    class="toggle-group w-100  toggle-options"
    appearance="legacy"
    name="transactionType"
    aria-label="Transaction Type"
  >
    <mat-button-toggle
      [ngClass]="{ 'active-toggle': activeTab === 'LEASE' }"
      [checked]="activeTab === 'LEASE'"
      (change)="setActiveTab('LEASE')"
      class="toggle-btn"
      [disabled]="disableToggle('LEASE')"
      [matTooltip]="toolTipText('LEASE')"
      matTooltipPosition="below"
    >
      {{ "lease" | translate }}
    </mat-button-toggle>
    <mat-button-toggle
      [ngClass]="{ 'active-toggle': activeTab === 'SALE' }"
      [checked]="activeTab === 'SALE'"
      (change)="setActiveTab('SALE')"
      class="toggle-btn"
      [disabled]="disableToggle('SALE')"
      [matTooltip]="toolTipText('SALE')"
      matTooltipPosition="below"
    >
      {{ "sale" | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>

  <app-transactions-table
    [transactions]="getActiveTransactions()"
    [columnDefs]="getActiveColumnDefs(activeTab)"
    [txnType]="activeTab"
  ></app-transactions-table>

  <button mat-raised-button (click)="viewAll()" class="view-all-button">
    {{ "transaction.viewAll" | translate }}
  </button>
</div>
