import {
  Component,
  ChangeDetectorRef,
  ViewChild,
  ChangeDetectionStrategy,
  Input,
  SimpleChanges
} from "@angular/core";
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { ListingsService } from "../../../core/services/listings.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ResponsiveSlideHelper } from "../../../core/_helpers";
import { CarouselPager } from "../../../core/models";
import { Directions } from "../../../core/models/enums/direction.enum";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { NGXLogger } from "ngx-logger";
import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";
import { environment } from "../../../../environments/environment";
import { Subscription } from "rxjs";
export interface Brand {
  value: Number;
  viewValue: string;
}
@Component({
  selector: "featured-listings",
  templateUrl: "./featured-listings.component.html",
  styleUrls: ["./featured-listings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedListingsComponent implements CarouselPager {
  @Input() latamCountry: string = "";
  @ViewChild("slickCarousel", { static: true })
  carousel: SlickCarouselComponent;
  isMobile = false;
  responsiveSub: Subscription;
  currentCode: string;
  pageSize = 5;
  @Input() listings: any[] = [];
  @Input() hideLogo = false;
  listings2: any;
  handsetMode: boolean;

  currentPage: number = 1;
  totalItemCount: number = 0;
  direction: string = "next";
  disableNext: boolean = false;
  lastPageLoaded: boolean = false;
  activeElements: any = [];
  visibleElements: any = [];
  slideProgress: string = "";
  searchButtonText: string = "Listings Search";

  slideConfig: any = ResponsiveSlideHelper.DEFAULT_SLIDE_CONFIG;
  slideBreakpoints: any = ResponsiveSlideHelper.SLIDE_BREAKPOINTS;

  lastSlideActiveCehck: boolean;
  count: number = 0;

  resizingTotalSlideCount: any;
  currentPage2: any;
  search = "";
  showNoListingsMessage = false;
  slideCurrentIndex = 1;

  constructor(
    private listingService: ListingsService,
    private breakpointObserver: BreakpointObserver,
    private _cdr: ChangeDetectorRef,
    private _log: NGXLogger,
    private responsive: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.handsetMode = result.matches;
      });
    this.currentCode = environment.platformId;
  }

  ngOnInit() {
    if (this.listings.length == 0) {
      this.getActiveListings(this.pageSize);
    }
    this.totalItemCount = this.listings.length;
    this.slideConfig.arrows = false;
    this.responsiveChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes?.latamCountry?.firstChange &&
      !changes?.hideLogo.currentValue
    ) {
      this.listings = [];
      this.getActiveListings(this.pageSize);
    }
  }

  ngAfterViewChecked(): void {
    this.activeElements = this.getActiveSlides();
    this.visibleElements = this.getVisibleElementsCount();
    if (this.activeElements.length > 0 && this.count == 0) {
      this.count = 1;
      this.setLastSlideActiveCheck(
        this.activeElements.length <= this.visibleElements
      );
    }
    this._cdr.detectChanges();
  }

  async getActiveListings(pageSize: number) {
    this.listingService.latamCountry = this.latamCountry;
    this.listingService
      .getListings()
      .then(listings => (this.listings = listings))
      .catch(error => {
        this._log.error(error);
        this.showNoListingsMessage = true;
      });

    this._cdr.markForCheck();
  }

  getActiveSlides() {
    const list = document.getElementById("slick-listings");
    const activeElements = list.querySelectorAll(
      ".slick-slide.ng-star-inserted"
    );

    return activeElements;
  }

  getVisibleElementsCount() {
    const list = document.getElementById("slick-listings");
    const activeElements = list.querySelectorAll(
      ".slick-slide.slick-active.ng-star-inserted"
    ).length;

    return activeElements;
  }

  loadPage(direction: string, carousel: any) {
    switch (direction) {
      case Directions.Next:
        const lastSlideActive = this.isEndOfSlideTrack();

        if (!lastSlideActive) {
          carousel.slickNext();
          this._cdr.detectChanges();
          this.setLastSlideActiveCheck(
            this.currentPage2 === this.resizingTotalSlideCount
          );
        } else if (
          lastSlideActive &&
          this.activeElements.length === this.totalItemCount
        ) {
          this.setLastSlideActiveCheck(true);
          carousel.slickNext();
        } else {
          this.appendPage(Directions.Next, carousel);
        }
        break;
      case Directions.Back:
        this.prevSlide(carousel);
        this.setLastSlideActiveCheck(false);
        break;
    }
  }

  isEndOfSlideTrack(): boolean {
    if (
      this.getVisibleElementsCount() < 2 ||
      this.activeElements.length !== this.totalItemCount
    ) {
      if (
        !this.activeElements[this.activeElements.length - 1].classList.contains(
          "slick-active"
        ) &&
        this.activeElements.length > 3
      ) {
        return false;
      }
      return this.activeElements[
        this.activeElements.length - 2
      ].classList.contains("slick-active");
    } else if (
      this.getVisibleElementsCount() < 4 &&
      this.activeElements.length !== this.totalItemCount
    ) {
      return this.activeElements[
        this.activeElements.length - 2
      ].classList.contains("slick-active");
    } else {
      if (this.activeElements.length === this.totalItemCount) {
        return this.activeElements[
          this.activeElements.length - 2
        ].classList.contains("slick-active");
      }
      if (
        !this.activeElements[this.activeElements.length - 1].classList.contains(
          "slick-active"
        )
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  async appendPage(direction: string, carousel: any) {
    const currentPage = this.currentPage;
    switch (direction) {
      case Directions.Next:
        this.disableNext = true;
        const nextPage = !this.lastPageLoaded ? currentPage + 1 : currentPage;
        this.currentPage = nextPage;
        /*  const page = this.listingService.fetchPage(nextPage);
        const listings = await page.pageQuery$().then(pageListings => {
          pageListings.forEach(listing => this.listings.push(listing));
          return pageListings;
        });

        if (
          listings.length &&
          this.activeElements.length !== this.totalItemCount
        ) {
          this.lastPageLoaded = false;
          setTimeout(() => {
            this.nextSlide(carousel);
            this.disableNext = false;
          }, 800);
        } else { */
        this.lastPageLoaded = true;
        this.disableNext = false;
        carousel.slickGoTo(0);
        /* } */
        break;
      case Directions.Back:
        break;
    }
  }

  slideProgressData() {
    const visibleElements = this.getVisibleElementsCount();
    const rounded = (this.totalItemCount / visibleElements) % 1 > 0;
    const elementsPerPage = this.totalItemCount / visibleElements;
    const resizingTotalSlideCount = rounded
      ? Math.ceil(elementsPerPage)
      : Math.floor(elementsPerPage);
    const currentTrackIndex = this.slideCurrentIndex + 1;
    const currentPage =
      visibleElements > 1
        ? Math.ceil(currentTrackIndex / this.visibleElements)
        : currentTrackIndex;
    this.currentPage2 = currentPage;
    this.resizingTotalSlideCount = resizingTotalSlideCount;

    return {
      visibleElements,
      rounded,
      elementsPerPage,
      resizingTotalSlideCount,
      currentTrackIndex,
      currentPage
    };
  }

  getSlideProgress(): string {
    const { resizingTotalSlideCount, currentPage } = this.slideProgressData();
    const displayText =
      currentPage && resizingTotalSlideCount
        ? `${currentPage} of ${resizingTotalSlideCount}`
        : "";
    return displayText;
  }

  nextSlide(carousel: any) {
    if (!carousel) {
      return;
    }
    const { resizingTotalSlideCount, currentPage } = this.slideProgressData();
    const restartSlides = currentPage === resizingTotalSlideCount;
    if (!restartSlides) {
      carousel.slickNext();
      this._cdr.detectChanges();
      this.setLastSlideActiveCheck(
        this.currentPage2 === this.resizingTotalSlideCount
      );
    } else {
      this.setLastSlideActiveCheck(true);
    }
  }

  onKeyup($event) {
    window.open(
      `${environment.spotURL}/spot-search?keyword=${this.search}`,
      "_blank"
    );
  }

  redirectSpot(download: any = null) {
    if (download) {
      window.open(`${environment.spotURL}/download`, "_blank");
    } else {
      window.open(`${environment.spotURL}`, "_blank");
    }
  }

  prevSlide(carousel: any) {
    if (!carousel) {
      return;
    }
    carousel.slickPrev();
  }

  /**
   * @param  {} event
   */
  slickInit(event) {}

  /**
   * @param  {} event
   */
  afterChange(e) {
    const { currentSlide } = e;
    this.slideCurrentIndex = currentSlide;
  }

  /**
   * @param  {} event
   */
  breakpoint(event) {}

  /**
   * @param  {} event
   */
  beforeChange(event) {}

  /**
   * @param  {} event
   */
  swipe(event) {
    const { isFinal, direction } = event;

    const currentTrackIndex = this.slideCurrentIndex + 1;
    const resizingTotalSlideCount = Math.ceil(
      this.listingService.getTotalPageCount / this.getVisibleElementsCount()
    );

    if (
      isFinal &&
      direction === 2 &&
      currentTrackIndex < resizingTotalSlideCount &&
      this.isEndOfSlideTrack() &&
      !this.disableNext
    ) {
      this.appendPage(Directions.Next, this.carousel);
    }
  }

  responsiveChanges() {
    this.responsiveSub = this.responsive
      .observe(Breakpoints.XSmall)
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  setLastSlideActiveCheck(flag: boolean) {
    this.lastSlideActiveCehck = flag || this.isEndOfSlideTrack();
  }
}
