<div
  *ngIf="isMultifamily"
  id="areas-measurements-multicolumns"
  [ngClass]="{
    'show-less': areasMeasurementsOpts.length > 10
  }"
  class="col-md-12 border-table p-0"
>
  <div
    *ngFor="let keyFact of areasMeasurementsOpts"
    [ngClass]="{ 'display-none': !keyFact?.enabled }"
    class="key-facts-multicolumns"
  >
    <div *ngIf="keyFact.enabled" class="column label-color">
      {{ keyFact.displayName }}
    </div>
    <div class="column key-facts-value label-color2">
      {{ keyFact.value }}
    </div>
  </div>
</div>
<div class="d-flex key-facts-columns border-table">
  <div class="col-md-12 p-0">
    <mat-button-toggle-group
      class="toggle-group w-100 toggle-options"
      appearance="legacy"
      name="fontStyle"
      aria-label="Font Style"
    >
      <mat-button-toggle
        [ngClass]="{ 'active-toggle': typeOpt === 1 }"
        (click)="typeOpt = 1"
        class="toogle-btn"
      >
        {{ "detail.metricMx" | translate }}</mat-button-toggle
      >
      <mat-button-toggle
        [ngClass]="{
          'active-toggle': typeOpt === 2
        }"
        (click)="typeOpt = 2"
        class="toogle-btn"
      >
        {{ "detail.imperialUs" | translate }}</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <div
    *ngIf="property.buildingType.id == 4001"
    class="column label-color flexB-60"
  >
    {{
      typeOpt === 1
        ? ("detail.totalGla" | translate)
        : ("detail.totalGlaFt" | translate)
    }}
  </div>
  <div *ngIf="property.buildingType.id == 4001" class="column label-color2">
    {{
      typeOpt === 1
        ? (complexInformation?.totalArea | localNumber: "1.2-2")
        : (complexInformation?.totalArea * 10.764 | localNumber: "1.0-0")
    }}
  </div>
  <div
    *ngIf="property.buildingType.id == 4001"
    class="column label-color flexB-60"
  >
    {{
      typeOpt === 1
        ? ("detail.builtGla" | translate)
        : ("detail.builtGlaFt" | translate)
    }}
  </div>
  <div *ngIf="property.buildingType.id == 4001" class="column label-color2">
    {{
      typeOpt === 1
        ? (complexInformation?.constructedArea | localNumber: "1.2-2")
        : (complexInformation?.constructedArea * 10.764 | localNumber: "1.0-0")
    }}
  </div>
  <div
    *ngIf="
      property.buildingType.id == 4001 &&
      complexInformation?.underConstructionArea
    "
    class="column label-color flexB-60"
    style="margin-right: -56px;"
  >
    {{
      typeOpt === 1
        ? ("detail.underConstructionGla" | translate)
        : ("detail.underConstructionGlaFt" | translate)
    }}
  </div>
  <div
    *ngIf="
      property.buildingType.id == 4001 &&
      complexInformation?.underConstructionArea
    "
    class="column label-color2"
  >
    {{
      typeOpt === 1
        ? (complexInformation?.underConstructionArea | localNumber: "1.2-2")
        : (complexInformation?.underConstructionArea * 10.764
          | localNumber: "1.0-0")
    }}
  </div>
  <div
    *ngIf="property.buildingType.id == 4001 && complexInformation?.plannedArea"
    class="column label-color flexB-60"
  >
    {{
      typeOpt === 1
        ? ("detail.planned" | translate)
        : ("detail.plannedFt" | translate)
    }}
  </div>
  <div
    *ngIf="property.buildingType.id == 4001 && complexInformation?.plannedArea"
    class="column label-color2"
  >
    {{
      typeOpt === 1
        ? (complexInformation?.plannedArea | localNumber: "1.2-2")
        : (complexInformation?.plannedArea * 10.764 | localNumber: "1.0-0")
    }}
  </div>
  <div
    class="column label-color"
    *ngIf="
      (property.buildingType.id == 1001 ||
        property?.buildingType.id == 2001 ||
        property?.buildingType.id == 3001 ||
        property?.buildingType.id == 5001) &&
      property?.rentableArea
    "
  >
    {{
      typeOpt === 1
        ? ("detail.grossLeaseableArea" | translate)
        : ("detail.grossLeaseableAreaFt" | translate)
    }}
  </div>
  <div
    class="column label-color2"
    *ngIf="
      (property.buildingType.id == 1001 ||
        property?.buildingType.id == 2001 ||
        property?.buildingType.id == 3001 ||
        property?.buildingType.id == 5001) &&
      property?.rentableArea
    "
  >
    {{
      typeOpt === 1
        ? (property?.rentableArea | localNumber: "1.2-2")
        : (property?.rentableArea * 10.764 | localNumber: "1.0-0")
    }}
  </div>
  <div
    class="column label-color"
    *ngIf="
      (property.buildingType.id == 1001 ||
        property?.buildingType.id == 2001 ||
        property?.buildingType.id == 3001) &&
      property?.landArea
    "
  >
    {{
      typeOpt === 1
        ? ("detail.landArea" | translate)
        : ("detail.landAreaFt" | translate)
    }}
  </div>
  <div
    class="column label-color2"
    *ngIf="
      (property.buildingType.id == 1001 ||
        property?.buildingType.id == 2001 ||
        property?.buildingType.id == 3001) &&
      property?.landArea
    "
  >
    {{
      typeOpt === 1
        ? (property?.landArea | localNumber: "1.2-2")
        : (property?.landArea * 10.764 | localNumber: "1.0-0")
    }}
  </div>
  <div
    class="column label-color"
    *ngIf="property?.buildingType.id == 5001 && property?.rooms"
  >
    {{ "detail.rooms" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="property?.buildingType.id == 5001 && property?.rooms"
  >
    {{ property?.rooms }}
  </div>
  <div
    class="column label-color"
    *ngIf="property?.buildingType.id == 3001 && property?.numberOfTenants"
  >
    {{ "detail.numberOfTenants" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="property?.buildingType.id == 3001 && property?.numberOfTenants"
  >
    {{ property?.numberOfTenants }}
  </div>
  <div
    class="column label-color"
    *ngIf="property?.buildingType.id == 3001 && property?.majorTenants"
  >
    {{ "detail.majorTenants" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="property?.buildingType.id == 3001 && property?.majorTenants"
  >
    {{ property?.majorTenants }}
  </div>
  <div
    class="column label-color"
    *ngIf="property?.buildingType.id == 3001 && property?.totalShops"
  >
    {{ "detail.totalShops" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="property?.buildingType.id == 3001 && property?.totalShops"
  >
    {{ property?.totalShops }}
  </div>
  <div
    class="column label-color"
    *ngIf="property?.buildingType.id == 3001 && property?.totalOccupiedShops"
  >
    {{ "detail.totalOccupiedShops" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="property?.buildingType.id == 3001 && property?.totalOccupiedShops"
  >
    {{ property?.totalOccupiedShops }}
  </div>
  <div
    class="column label-color"
    *ngIf="property?.buildingType.id == 3001 && property?.totalAvailableShops"
  >
    {{ "detail.totalAvailableShops" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="property?.buildingType.id == 3001 && property?.totalAvailableShops"
  >
    {{ property?.totalAvailableShops }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.floorArea"
    class="column label-color"
  >
    {{
      typeOpt === 1
        ? ("detail.avgFloorPrivateArea" | translate)
        : ("detail.avgFloorPrivateAreaFt" | translate)
    }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.floorArea"
    class="column label-color2"
  >
    {{
      typeOpt === 1
        ? (property?.floorArea | localNumber: "1.2-2")
        : (property?.floorArea * 10.764 | localNumber: "1.0-0")
    }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.commonAreaFactor"
    class="column label-color"
  >
    {{ "detail.commonAreaFactor" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.commonAreaFactor"
    class="column label-color2"
  >
    {{ property?.commonAreaFactor }}%
  </div>
  <div
    class="column label-color"
    *ngIf="
      (property.buildingType.id == 1001 || property?.buildingType.id == 2001) &&
      property?.ceilingHeight
    "
  >
    {{
      typeOpt === 1
        ? ("detail.ceilingHeight" | translate)
        : ("detail.ceilingHeightFt" | translate)
    }}
  </div>

  <div
    class="column label-color2"
    *ngIf="
      (property.buildingType.id == 1001 || property?.buildingType.id == 2001) &&
      property?.ceilingHeight
    "
  >
    {{
      typeOpt === 1
        ? (property?.ceilingHeight | localNumber: "1.2-2")
        : (property?.ceilingHeight * 3.28084 | localNumber: "1.0-0")
    }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property.elevators"
    class="column label-color"
  >
    {{ "detail.elevators" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property.elevators"
    class="column label-color2"
  >
    {{ property.elevators }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.numberStories"
    class="column label-color"
  >
    {{ "detail.totalFloor" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.numberStories"
    class="column label-color2"
  >
    {{ property?.numberStories }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.retailStories"
    class="column label-color"
  >
    {{ "detail.retailStories" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.retailStories"
    class="column label-color2"
  >
    {{ property?.retailStories }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.officeStories"
    class="column label-color"
  >
    {{ "detail.officeStories" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.officeStories"
    class="column label-color2"
  >
    {{ property?.officeStories }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.parkingStories"
    class="column label-color"
  >
    {{ "detail.parkingStories" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.parkingStories"
    class="column label-color2"
  >
    {{ property?.parkingStories }}
  </div>
  <div
    class="column label-color"
    *ngIf="property.buildingType.id == 1001 && property?.floorLoad"
  >
    {{
      typeOpt === 1
        ? ("detail.floorLoadmx" | translate)
        : ("detail.floorLoadPsi" | translate)
    }}
  </div>
  <div
    class="column label-color2"
    *ngIf="property.buildingType.id == 1001 && property?.floorLoad"
  >
    {{
      typeOpt === 1
        ? (property?.floorLoad | localNumber: "1.2-2")
        : (property?.floorLoad * 14.2233 | localNumber: "1.2-2")
    }}
  </div>
  <div
    class="column label-color"
    *ngIf="property.buildingType.id == 1001 && property?.officeArea"
  >
    {{
      typeOpt === 1
        ? ("detail.officeArea" | translate)
        : ("detail.roundedOfficeAreaFt" | translate)
    }}
  </div>
  <div
    class="column label-color2"
    *ngIf="property.buildingType.id == 1001 && property?.officeArea"
  >
    {{
      typeOpt === 1
        ? (property?.officeArea | localNumber: "1.2-2")
        : (property?.officeArea * 10.764 | localNumber: "1.0-0")
    }}
  </div>

  <div
    class="column label-color"
    *ngIf="
      property.buildingType.id == 1001 &&
      property?.rentableArea &&
      property?.officeArea
    "
  >
    {{ "detail.officePercentage" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="
      property.buildingType.id == 1001 &&
      property?.rentableArea &&
      property?.officeArea
    "
  >
    {{ (property?.officeArea / property?.rentableArea) * 100 | localNumber }}%
  </div>
  <div
    *ngIf="property?.buildingType.id == 1001 && property?.docks"
    class="column label-color"
  >
    {{ "detail.numberOfDocks" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 1001 && property?.docks"
    class="column label-color2"
  >
    {{ property?.docks }}
  </div>
  <div
    *ngIf="
      (property.buildingType.id == 1001 ||
        property?.buildingType.id == 2001 ||
        property?.buildingType.id == 3001) &&
      property?.parkingCovered
    "
    class="column label-color"
  >
    {{ "detail.parkingCovered" | translate }}
  </div>
  <div
    *ngIf="
      (property.buildingType.id == 1001 ||
        property?.buildingType.id == 2001 ||
        property?.buildingType.id == 3001) &&
      property?.parkingCovered
    "
    class="column label-color2"
  >
    {{ property?.parkingCovered }}
  </div>
  <div
    *ngIf="
      (property.buildingType.id == 1001 ||
        property?.buildingType.id == 2001 ||
        property?.buildingType.id == 3001) &&
      (property?.parkingUncovered || property?.hasParkingUncovered)
    "
    class="column label-color"
  >
    {{ "detail.parkingUncovered" | translate }}
  </div>
  <div
    *ngIf="
      (property.buildingType.id == 1001 ||
        property?.buildingType.id == 2001 ||
        property?.buildingType.id == 3001) &&
      (property?.parkingUncovered || property?.hasParkingUncovered)
    "
    class="column label-color2"
  >
    {{
      property?.parkingUncovered
        ? property?.parkingUncovered
        : ("detail.yes" | translate)
    }}
  </div>
  <div
    *ngIf="
      (property.buildingType.id == 1001 ||
        property?.buildingType.id == 2001 ||
        property?.buildingType.id == 3001) &&
      property?.docks &&
      property?.rentableArea
    "
    class="column label-color"
  >
    {{
      typeOpt === 1
        ? ("detail.dockRatio" | translate)
        : ("detail.dockRatioFt" | translate)
    }}
  </div>
  <div
    *ngIf="
      (property.buildingType.id == 1001 ||
        property?.buildingType.id == 2001 ||
        property?.buildingType.id == 3001) &&
      property?.docks &&
      property?.rentableArea
    "
    class="column label-color2"
  >
    {{
      typeOpt === 1
        ? ((property?.docks / property?.rentableArea) * 1000
          | localNumber: "1.2-2")
        : ((property?.docks / (property?.rentableArea * 10.764)) * 10000
          | localNumber: "1.2-2")
    }}
  </div>
  <div
    *ngIf="property.buildingType.id == 1001 && property?.drivingDistance"
    class="column label-color"
  >
    {{ getDrivingDistanceLabel() }}
  </div>
  <div
    *ngIf="property.buildingType.id == 1001 && property?.drivingDistance"
    class="column label-color2"
  >
    {{
      typeOpt === 1
        ? (property?.drivingDistance | localNumber: "1.0-0")
        : (property?.drivingDistance * 0.621371 | localNumber: "1.0-0")
    }}
  </div>
</div>
