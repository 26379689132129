import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class TransactionsService {
  constructor(private http: HttpClient) {}

  getTransactionId(id) {
    return this.http
      .get(`${environment.apiURL}/realStateTransactions/${id}`)
      .toPromise();
  }

  downloadReport(id) {
    return this.http
      .post(`${environment.apiURL}/transactionPrints/word/transaction`, id)
      .toPromise();
  }

  getSaleCapratesData(id) {
    return this.http
      .post(`${environment.apiURL}/transaction/sales-caprates-data`, {
        buildingId: id
      })
      .toPromise();
  }

  getLeaseData(id) {
    return this.http
      .post(`${environment.apiURL}/transaction/leases-data`, {
        buildingId: id
      })
      .toPromise();
  }

  getTransactions(buildingId: number) {
    return this.http
      .get(
        `${environment.apiURL}/transaction/transactionsByBuildingId?buildingId=${buildingId}`
      )
      .toPromise();
  }

  getCorporationPortfolio(portfolioId) {
    return this.http
      .get(
        `${environment.apiURL}/corporation-portfolio/information?portfolioId=${portfolioId}`
      )
      .toPromise();
  }

  getFundPortfolio(portfolioId) {
    return this.http
      .get(
        `${environment.apiURL}/real-estate-fund-portfolio/information?portfolioId=${portfolioId}`
      )
      .toPromise();
  }
}
